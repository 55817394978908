import { createSlice } from '@reduxjs/toolkit';

const sceneSlice = createSlice({
  name: 'scenes',
  initialState: [],
  reducers: {
    setScenes: (state, action) => {
      return action.payload;
    },
    addScene: (state, action) => {
      state.push(action.payload);
      return state;
    },
    addScenesBunch: (state, action) => {
      state.push(...action.payload);
      return state;
    }
  },
});

export const { setScenes, addScene, addScenesBunch } = sceneSlice.actions;

export default sceneSlice.reducer;
