import { Button, Fade, Flex, Text, useDisclosure } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ModalComponent from "../../../components/ModalComponent";
import OrderDetails from "./OrderDetails";
import { useRef } from "react";

export default function OrdersSection() {
  const { t } = useTranslation();
  const allOrders = useSelector(store => store.orders);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const orderId = useRef()

  return (
    <>
      <Text
        fontSize='28px'
        fontWeight='500'
        lineHeight='36px'
        color='#000000'
      >
        {t('profile.side-section.my-orders')}
      </Text>
      <Fade
        in
        transition={{ enter: { duration: 0.5 } }}
      >
        <Flex
          w='100%'
          bg='#ffffff'
          borderRadius='12px'
          p='24px 30px'
          flexDirection='column'
        >
          {allOrders?.length > 0 ?
            allOrders.map((order, index) => (
              <Flex
                key={`order${order.id}`}
                w='100%'
                justifyContent='space-between'
                alignItems='center'
                h='90px'
                borderBottom={index + 1 !== allOrders?.length ? '1px solid #e9e9e9' : 'none'}
              >
                <Text
                  fontSize='18px'
                  fontWeight='500'
                  lineHeight='22px'
                  color='#000000'
                >
                  {`${t('profile.orders-section.order')} #${order.id}`}
                </Text>
                <Flex
                  flexDirection='column'
                  gap='3px'
                >
                  <Text
                    fontSize='18px'
                    fontWeight='500'
                    lineHeight='22px'
                    color='#000000'
                  >
                    ${order.orderInfo.total_sum}
                  </Text>
                  <Text
                    fontSize='16px'
                    fontWeight='500'
                    lineHeight='20px'
                    color='#9f9c9c'
                  >
                    {new Date(order.orderInfo.createdAt).toLocaleDateString()}
                  </Text>
                </Flex>
                <Text
                  fontSize='14px'
                  fontWeight='700'
                  lineHeight='14px'
                  color='#C5AB50'
                  borderRadius='42px'
                  p='11px 18px'
                  h='36px'
                  bg='#C5AB5010'
                >
                  {t(`profile.orders-section.order-status.${order.orderInfo.delivery_status}`)}
                </Text>
                <Button
                  h='46px'
                  color='#000000'
                  fontSize='12px'
                  lineHeight='12px'
                  fontWeight='500'
                  border='1px solid #e9e9e9'
                  borderRadius='12px'
                  p="18px 24px"
                  bg='none'
                  onClick={() => {
                    onOpen();
                    orderId.current = order.id;
                  }}
                  _focus={{ borderColor: '#B19477' }}
                  _hover={{ backgroundColor: '#B1947733' }}
                  _active={{ backgroundColor: '#B1947740' }}
                >
                  {t('profile.orders-section.button')}
                </Button>
              </Flex>
            ))
            :
            <Text
              fontSize='18px'
              fontWeight='500'
              lineHeight='22px'
              color='#000000'
            >
              {t('profile.orders-section.no-orders')}
            </Text>
          }
        </Flex>
        <ModalComponent
          isOpen={isOpen}
          onClose={onClose}
          children={<OrderDetails allOrders={allOrders} orderId={orderId.current} onClose={onClose} />}
        />
      </Fade>
    </>
  )
}