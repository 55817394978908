import { Button, Image } from "@chakra-ui/react";
import ExitSvg from '../../components/Images/Exit.svg';

export default function BtnExit({ handlerSwitch, top = 'auto', right = 'auto', bottom = 'auto', left = 'auto' }) {
  return (
    <Button
      zIndex='2'
      position='absolute'
      top={top}
      right={right}
      bottom={bottom}
      left={left}
      background='none'
      width='20px'
      minW='20px'
      height='20px'
      className='group'
      transition='all 0.2s ease-in-out'
      p='0'
      _hover={{background: 'none', transform: 'scale(1.1)' }}
      _active={{transform: 'scale(0.9)'}}
      onClick={handlerSwitch}
    >
      <Image 
        w='20px'
        maxW='20px'
        p='0'
        src={ExitSvg} 
        />
    </Button> 
  )
}