import { Fade, Flex, Text } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ViewScenes from "../../../components/Scenes/ViewScenes";

export default function ScenesSection() {
  const { t } = useTranslation();
  const user = useSelector((store) => store.user);
  const allScenes = useSelector((store) => store.scenes);
  const userScenes = allScenes?.filter((scene) => scene.user_keycloak_id === user.id);

  return (
    <>
      <Text
        fontSize='28px'
        fontWeight='500'
        lineHeight='36px'
        color='#000000'
      >
        {t('profile.side-section.my-scenes')}
      </Text>
      <Fade
        in
        transition={{ enter: { duration: 0.5 } }}
      >
        <Flex
          w='100%'
          bg='#ffffff'
          borderRadius='12px'
          p='24px 30px'
          flexDirection='column'
        >
          <Text
            fontSize='26px'
            fontWeight='400'
            lineHeight='28px'
            color='#000000'
            mb='20px'
          >
            {t('profile.scenes-section.count')}{userScenes?.length}
          </Text>
          {allScenes?.length > 0 &&
            <ViewScenes allScenes={userScenes} />
          }
        </Flex>
      </Fade>
    </>
  )
};