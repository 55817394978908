import { Flex, Image, Text } from "@chakra-ui/react";
import BtnExit from "../../components/Buttons/BtnExit";
import { useTranslation } from "react-i18next";
import SuccessOrderPNG from '../../components/Images/SuccessOrder.png';
import BtnGoToOrder from "../../components/Buttons/BtnGoToOrder";

export default function SuccessOrder({ onClose }) {
  const { t } = useTranslation();
  return (
    <Flex
      w='640px'
      h='535px'
      borderRadius='12px'
      bg='#f9f9f9'
      zIndex='3'
      position='relative'
      flexDirection='column'
      alignItems='center'
      p='48px 0 43px'
    >
      <BtnExit
        handlerSwitch={onClose}
        top={'30px'}
        right={'37px'}
      />
      <Text
        fontSize='26px'
        lineHeight='23px'
        fontWeight='500'
        color='#000000'
      >
        {t('checkout.success.header')}
      </Text>
      <Image
        src={SuccessOrderPNG}
      />
      <BtnGoToOrder />
    </Flex>
  )
}