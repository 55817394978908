import {
  Fade,
  Flex,
  Skeleton,
  SkeletonCircle
} from "@chakra-ui/react"
import SceneItem from "../../components/Scenes/SceneItem"

export default function ItemsList({itemId, data}) {
  const Card = (item, index) => {
      return (
        <Fade
          in
          transition={{ enter: { duration: 0.2, delay: index * 0.1 } }}
          key={`item${item.id}`}
        >
          <SceneItem
            item={item}
          />
        </Fade>
      )
    }

    if (itemId && data.dotId !== 'no_data') {
      return data?.data?.map((item, index) => (
        Card(item, index)
      ))
    }

    if (data.dotId !== 'no_data') {
      return data?.data?.map((item, index) => (
        Card(item, index)
      ))
    }

  return data.data.map((item, index) => (
    <Fade
      in
      transition={{ enter: { duration: 0.2, delay: index * 0.1 } }}
      key={`item${item.id}`}
    >
      <Flex
        w='282px'
        flexDirection='column'
        borderRadius='12px'
        alignItems='center'
        background='#ffffff'
        overflow='hidden'
        pb='18px'
        mb='12px'
        gap='14px'
      >
        <Skeleton
          w='100%'
          h='150px'
        />
        <Flex
          width='230px'
          h='37px'
          gap='10px'
          alignItems='center'
          justifyContent='space-between'
        >
          <Skeleton
            borderRadius='12px'
            h='20px'
            w='50%'
          />
          <SkeletonCircle
          />
        </Flex>
        <Flex
          width='230px'
          alignItems='center'
          justifyContent='space-between'
        >
          <Skeleton
            borderRadius='12px'
            w='30%'
            h='37px'
          />
          <Skeleton
            borderRadius='12px'
            w='25%'
            h='20px'
          />
        </Flex>
      </Flex>
    </Fade>
  ));
};

