import {
  Button,
  Flex,
  Text,
  Textarea,
  useToast
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import BtnExit from "../Buttons/BtnExit";
import { useState } from "react";
import { useSelector } from "react-redux";

export default function FeedbackModule({ onClose }) {
  const { t } = useTranslation();
  const toast = useToast();
  const [valid, setValid] = useState(false);
  const [textData, setTextData] = useState('');
  const user = useSelector(store => store.user);
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  
  const handlerTextarea = (e) => {
    setTextData(e.target.value);
  };

  const handlerSend = async () => {
    if (textData.length > 0 && textData.length < 500) {
      const response = await fetch(`${serverUrl}/feedback`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId: user.id, text: textData }),
      });

      if (response.ok) {
        toast({
          title: t('feedback.success'),
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        onClose();
      } else {
        toast({
          title: t('feedback.error'),
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      };
    } else {
      setValid(true);
    }
  };

  return (
    <Flex
      w='500px'
      minH='400px'
      borderRadius='12px'
      bg='#f9f9f9'
      zIndex='3'
      position='relative'
      flexDirection='column'
      alignItems='center'
      p='48px 40px 43px'
      gap='20px'
    >
      <BtnExit
        handlerSwitch={onClose}
        top={'30px'}
        right={'37px'}
      />
      <Text
        fontSize='26px'
        lineHeight='23px'
        fontWeight='500'
        color='#000000'
      >
        {t('feedback.header')}
      </Text>
      <Text
        fontSize='16px'
        lineHeight='23px'
        color='#000000'
      >
        {t('feedback.text')}
      </Text>
      <Flex
        w='100%'
        position='relative'
      >
      <Textarea
        border='1px solid #9b9b9b'
        borderRadius='8px'
        h='200px'
        isInvalid={valid && (textData.length === 0 || textData.length > 500)}
        resize='none'
        onChange={handlerTextarea}
        />
        <Text
          position='absolute'
          bottom='-20px'
          right='5px'
          color='#9b9b9b'
          fontSize='12px'
          zIndex='1'
          lineHeight='14px'
        >
          {textData.length}/500
        </Text>
      </Flex>
      <Button
        minW='263px'
        h='54px'
        color='#ffffff'
        fontSize='16px'
        fontWeight='700'
        borderRadius='12px'
        mt='15px'
        p="18px 24px"
        bg='#000000'
        _hover={{ backgroundColor: '#262626' }}
        _active={{ backgroundColor: '#333333' }}
        _focus={{ backgroundColor: '#B19477' }}
        onClick={handlerSend}
      >
        {t('feedback.button')}
      </Button>
    </Flex>
  );
};
