import { Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export default function BtnCancel({ onClose }) {
  const { t } = useTranslation();
  
  return (
    <Button
      w='166px'
      h='54px'
      color='#000000'
      fontSize='16px'
      fontWeight='500'
      border='1px solid #0000001A'
      borderRadius='12px'
      p="18px 24px"
      bg='none'
      onClick={onClose}
      _focus={{ borderColor: '#B19477'}}
      _hover={{ backgroundColor: '#B1947733' }}
      _active={{ backgroundColor: '#B1947740' }}
    >
        {t('profile.logout-modal.button-no')}
    </Button>
  )
}