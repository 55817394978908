import { Box, Fade } from '@chakra-ui/react';
import { keyframes } from '@emotion/react';
import { useScenes } from '../../services/hooks/sceneService';
import { useDispatch, useSelector } from 'react-redux';

export default function DotsSearch({
  arrCoor,
  imgRef,
  setItemId,
  allItems,
  cropImg,
  onOpen
}) {
  const { postDataToSeekItems } = useScenes();
  const activeDots = useSelector(store => store.watcher.activeDots);
  const user = useSelector(store => store.user);
  const dispatch = useDispatch();

  const loadingPulse = keyframes({
    '0%': {
      outline: '4px solid #fffff'
    },
    '50%': {
      outline: '4px solid #C5AB50'
    },
    '100%': {
      outline: '4px solid #ffffff'
    }
  });
  
  const coeficient = imgRef.current.width / arrCoor[0]?.image_dimension[0];

  const handleDotClick = (event) => {
    const index = event.currentTarget.tabIndex;
    imgRef.current.style = 'filter: none'
    const dotId = Number(event.currentTarget.dataset.itemid);
    
    if (user) {
      setItemId(dotId);
      if (!(allItems?.find(item => item.dotId === dotId)) && !activeDots?.includes(dotId)) {
        console.log('send to api');
        
        dispatch({ type: 'watcher/addActiveDots', payload: dotId })
  
        const dotData = arrCoor.find(item => item.id === dotId);
        const imgForPost = cropImg(dotData.image_width * coeficient, dotData.image_height * coeficient, dotData.bbox[0] * coeficient, dotData.bbox[1] * coeficient)
  
        const sendData = {
          bbox: dotData.bbox,
          cropped_img: imgForPost.slice(23),
          class: dotData.class,
          label: dotData.label,
          weights: dotData.weights,
          class_name: dotData.class_name,
          confidence: dotData.confidence,
          bbox_center: dotData.bbox_center,
          image_dimension: dotData.image_dimension
        }
  
        postDataToSeekItems(sendData, dotId)
      }
    } else {
      onOpen();
    }
      
    event.currentTarget.style = `
        left: ${(arrCoor[index].bbox[0]) * coeficient}px;
        top: ${(arrCoor[index].bbox[1]) * coeficient}px;
        width: ${(arrCoor[index].bbox[2] - arrCoor[index].bbox[0]) * coeficient}px;
        height: ${(arrCoor[index].bbox[3] - arrCoor[index].bbox[1]) * coeficient}px;
        border-left: 4px solid white;
        border-right: 4px solid white;
        border-top: 1px solid white;
        border-bottom: 1px solid white;
        border-radius: 20px;
        box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.3);
        z-index: 1;
      `;
  };

  const handleRectBlur = (event) => {
    const index = event.currentTarget.tabIndex;
    const isDotItems = allItems?.find(item => item.dotId === arrCoor[index].id);
    imgRef.current.style = ''
    console.log('arrCoor[index].id: ', arrCoor[index].id);
    event.currentTarget.style = `
      left: ${(arrCoor[index].bbox_center[0] - 10) * coeficient}px;
      top: ${(arrCoor[index].bbox_center[1] - 10) * coeficient}px;
      width: 8px;
      height: 8px;
      border-left: ${isDotItems ? '4px solid #748D63' : '4px solid #B19477'};
      border-right: ${isDotItems ? '4px solid #748D63' : '4px solid #B19477'};
      border-top: ${isDotItems ? '1px solid #748D63' : '1px solid #B19477'};
      border-bottom: ${isDotItems ? '1px solid #748D63' : '1px solid #B19477'};
      border-radius: 50%;
      box-shadow: none;
      z-index: 2;
      outline: 4px solid #ffffff;
      `;
  }

  return arrCoor.map((coor, index) => {
    const isDotItems = allItems?.find(item => item.dotId === coor.id);
    return (
      <Fade
        in
        transition={{ enter: { duration: 0.3, delay: index * 0.05 } }}
        key={`dot${coor.id}`}
      >
        <Box
          onClick={handleDotClick}
          onBlur={handleRectBlur}
          tabIndex={index}
          data-itemid={coor.id}
          position={'absolute'}
          transition={'all 0.2s linear, box-shadow, z-index, outline'}
          animation={activeDots?.includes(coor.id) && `${loadingPulse} 1s linear infinite`}
          style={{
            left: `${(coor.bbox_center[0] - 10) * coeficient}px`,
            top: `${(coor.bbox_center[1] - 10) * coeficient}px`,
            width: '8px',
            height: '8px',
            borderTop: `${isDotItems ? '1px solid #748D63' : '1px solid #B19477'}`,
            borderBottom: `${isDotItems ? '1px solid #748D63' : '1px solid #B19477'}`,
            borderLeft: `${isDotItems ? '4px solid #748D63' : '4px solid #B19477'}`,
            borderRight: `${isDotItems ? '4px solid #748D63' : '4px solid #B19477'}`,
            borderRadius: '50%',
            zIndex: 2,
            outline: '4px solid #ffffff',
          }}
        >
          <Box
            position='relative'
            top='-5px'
            left='-8px'
            zIndex='3'
            width='16px'
            height='16px'
          />
        </Box>
      </Fade>
    )
  });
};