import { Flex, Image, Text } from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';
import Step11 from '../../../components/Images/HIW/Step11.png';
import Step12 from '../../../components/Images/HIW/Step12.png';
import Step21 from '../../../components/Images/HIW/Step21.png';
import Step22 from '../../../components/Images/HIW/Step22.png';
import Step31 from '../../../components/Images/HIW/Step31.png';

export default function HIWModule() {
  const { t } = useTranslation();
  
  return (
        <Flex
          w='1218px'
          h='642px'
          alignItems='center'
          flexDirection='column'
          gap='66px'
          p='73px 31px 124px'
          position='relative'
        >
          <Text
            fontSize='46px'
            fontWeight='700'
            color='#000000'
            lineHeight='46px'
            letterSpacing='2px'
          >
            {t('main.hiw-module.header')}
          </Text>
          <Flex
            gap='92px'
          >
            <Flex
              w='324px'
              h='333px'
              flexDirection='column'
              alignItems='center'
              justifyContent='space-between'
            >
              <Flex>
                <Image
                  position='absolute'
                  top='193px'
                  left='83px'
                  src={Step12}
                />
                <Image
                  position='absolute'
                  top='295px'
                  left='53px'
                  src={Step11}
                />
              </Flex>
              <Flex
                flexDirection='column'
                alignItems='center'
                gap='8px'
              >
                <Text
                   fontSize='28px'
                   fontWeight='700'
                   color='#000000'
                   lineHeight='38px'
                 >
                  1. {t('main.hiw-module.step1')}
                 </Text>
                 <Text
                   fontSize='18px'
                   fontWeight='400'
                   color='#00000060'
                   lineHeight='30px'
                   w='324px'
                   h='90px'
                   textAlign='center'
                 >
                  {t('main.hiw-module.step1-text')}
                 </Text>
              </Flex>
            </Flex>
            <Flex
              w='324px'
              h='333px'
              flexDirection='column'
              alignItems='center'
              justifyContent='space-between'
            >
              <Flex>
                <Image
                  position='absolute'
                  top='193px'
                  left='501px'
                  src={Step21}
                />
                <Image
                  position='absolute'
                  top='175px'
                  left='475px'
                  src={Step22}
                />
              </Flex>
              <Flex
                flexDirection='column'
                alignItems='center'
                gap='8px'
              >
                <Text
                   fontSize='28px'
                   fontWeight='700'
                   color='#000000'
                   lineHeight='38px'
                 >
                  2. {t('main.hiw-module.step2')}
                 </Text>
                 <Text
                   fontSize='18px'
                   fontWeight='400'
                   color='#00000060'
                   lineHeight='30px'
                   w='324px'
                   h='90px'
                   textAlign='center'
                 >
                  {t('main.hiw-module.step2-text')}
                 </Text>
              </Flex>
            </Flex>
            <Flex
              w='324px'
              h='333px'
              flexDirection='column'
              alignItems='center'
              justifyContent='space-between'
            >
              <Flex>
                <Image
                  position='absolute'
                  top='175px'
                  left='892px'
                  src={Step22}
                />
                <Image
                  position='absolute'
                  top='228px'
                  left='961px'
                  src={Step31}
                />
              </Flex>
              <Flex
                flexDirection='column'
                alignItems='center'
                gap='8px'
              >
                <Text
                   fontSize='28px'
                   fontWeight='700'
                   color='#000000'
                   lineHeight='38px'
                 >
                  3. {t('main.hiw-module.step3')}
                 </Text>
                 <Text
                   fontSize='18px'
                   fontWeight='400'
                   color='#00000060'
                   lineHeight='30px'
                   w='324px'
                   h='90px'
                   textAlign='center'
                 >
                  {t('main.hiw-module.step3-text')}
                 </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
    )
}