import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const useScenes = () => {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((store) => store.user);
  const scenes = useSelector((store) => store.scenes);
  const { t } = useTranslation();

  const getImageBase64 = async (directUrl) => {
    try {
      const response = await fetch(`${serverUrl}/scene/get-image-base64?direct_url=${directUrl}`, {
        credentials: 'include'
      });
      const data = await response.json();
  
      return `data:image/png;base64,${data.image_in_base64}`
    } catch (error) {
      console.log('error: ', error);
      return `not-found`
    }
  };

  const getScenesAndSeekScenes = async () => {
    try {
      const response = await fetch(`${serverUrl}/scene/get-all-scenes`, {
        credentials: 'include'
      });

      const result = await response.json();

      dispatch({ type: 'scenes/setScenes', payload: result.scenes })
      dispatch({ type: 'seekScenes/setSeekScenes', payload: result.seekScenes })
      return result.scenes;
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const getUserScenes = async (userId, data) => {
    console.log('data: ', data);
    console.log('userId: ', userId);
    try {
      console.log('scenes: ', scenes);
      const scenesId = data.reduce((acc, scene) => {
        if (scene.user_keycloak_id === userId) {
          acc.push(scene.id);
        }
        return acc;
      }, []);
      console.log('scenesId: ', scenesId);
      const response = await fetch(`${serverUrl}/scene/get-user-scenes?userId=${userId}`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(scenesId)
      });
      const result = await response.json();
      dispatch({ type: 'scenes/addScenesBunch', payload: result.scenes })
      dispatch({ type: 'seekScenes/addSeekScenes', payload: result.seekScenes })
    } catch (error) {
      console.log('error: ', error);
    }
  }

  const getMoreScenes = async () => {
    try {
      const scenesId = scenes.map(scene => scene.id);
      const response = await fetch(`${serverUrl}/scene/get-more-scenes`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(scenesId)
      });
      const result = await response.json();
      dispatch({ type: 'scenes/addScenesBunch', payload: result.scenes })
      dispatch({ type: 'seekScenes/addSeekScenes', payload: result.seekScenes })
      return true;
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const handlerNewScene = ({ inputRef, newImg, toast }) => {
    const response = fetch(`${serverUrl}/scene/image-detect?scene_name=scene&userId=${user.id}`, {
      method: 'POST',
      body: newImg,
      credentials: 'include',
    });

    inputRef.current.value = '';

    response
      .then((res) => {
        if (!res.ok) {
          throw new Error(t('main.scan-module.upload-image-error'));
        }
        toast({
          title: "Success!",
          description: "Image is uploaded",
          status: "success",
          duration: 4000,
          isClosable: true,
        });
        return res.json();
      })
      .then((data) => {
        console.log(data);
        dispatch({ type: 'scenes/addScene', payload: data.scene });
        dispatch({ type: 'seekScenes/addSeekScenes', payload: data.seekScenes });
        navigate(`/scenes/show/${data.scene.id}`)
      })
      .catch((error) => {
        console.log(error);
        toast({
          title: "Try again",
          description: error.message,
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      });
    
    toast.promise(response, {
      success: { containerStyle: { display: 'none' }, duration: 0 },
      error: { title: 'Promise rejected', description: 'Something wrong' },
      loading: { title: 'Loading', description: 'Please wait' },
    })
  };

  const handlerDeleteScene = (id) => {
    const response = fetch(`${serverUrl}/scene/delete-scene/${id}`, {
      method: 'DELETE',
      credentials: 'include'
    });
    response
      .then(res => res.json())
      .then(data => {
        if (data.status === 200) {
          return true;
        }
        return false;
      })
      .catch(error => console.log(error));
  };

  const postDataToSeekItems = (sendData, dotId) => {
    const response = fetch(`${serverUrl}/scene/get-items`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ data: sendData, id: dotId }),
      credentials: 'include'
    })

    response
      .then((res) => {
        if (!res.ok) {
          throw new Error(`Server error, status: ${res.status}`);
        }
        return res.json()
      })
      .then((data) => {
        if (!data.status === 'connecting') {
          console.log('error');
        }
         
      })
      .catch((error) => {
        console.log(error);
      })
  };

  const postDataToSeekLensItems = (sendData) => {
    const response = fetch(`${serverUrl}/scene/get-lens-items`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(sendData),
      credentials: 'include'
    })
    
    response
      .then((res) => {
        if (!res.ok) {
          throw new Error(`Server error, status: ${res.status}`);
        }
        return res.json()
      })
      .then((data) => {
        if (!data.status === 'connecting') {
          console.log('error');
        }
         
      })
      .catch((error) => {
        console.log(error);
      })
  };

  const getSceneSeekItems = (arr) => {
    try {
      fetch(`${serverUrl}/scene/get-scene-seek-items`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(arr),
        credentials: 'include'
      });
    } catch (error) {
      console.log('error: ', error);
    }
  }
  

  return {
    getImageBase64,
    getSceneSeekItems,
    postDataToSeekLensItems,
    postDataToSeekItems,
    getScenesAndSeekScenes,
    handlerDeleteScene,
    handlerNewScene,
    getMoreScenes,
    getUserScenes
  };
};

export { useScenes };