import React, { 
  useEffect,
  useRef, 
  useState 
} from "react";
import { 
  Box,
  Button,
  Image as ChakraImage, 
  Fade, 
  Flex,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ScanRect from '../../components/Images/ScanRect.svg';
import ScanDots from '../../components/Images/ScanDots.svg';
import Loader from "../../components/Loader";
import LensSearch from "./LensSearch";
import DotsSearch from "./DotsSearch";
import { useScenes } from "../../services/hooks/sceneService";
import ModalComponent from "../../components/ModalComponent";
import AuthModule from "../../components/Auth/AuthModule";
import ScanTutorial from "./ScanTutorial";
import ItemsList from "./ItemsList";

const mock =
  {
    dotId: 'no_data',
    data: [
      {
        image_url: "",
        name: 'title1',
        id: 1,
        price: 3500
      },
      {
        image_url: "",
        name: 'title1',
        id: 2,
        price: 3500
      },
      {
        image_url: "",
        name: 'title1',
        id: 3,
        price: 3500
      },
      {
        image_url: "",
        name: 'title1',
        id: 4,
        price: 3500
      }
    ]
  }

export default function ScanScene() {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const { sceneId } = useParams();
  const { t } = useTranslation();
  const imgRef = useRef(null);
  const frameRef = useRef(null);
  const loadedImg = useRef(null);
  const arrayCroppedImg = useRef(null);
  const arrayOfSeekSceensId = useRef(null);
  const [itemId, setItemId] = useState(null);
  const [trigger, setTrigger] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [sceneById, setSceneById] = useState(null);
  const [lensSwitch, setLensSwitch] = useState(true);
  const lensDotId = useSelector((store) => store.watcher.lensDotId);
  const seekScenes = useSelector((store) => store.seekScenes);
  const user = useSelector((store) => store.user);
  const allScenes = useSelector((store) => store.scenes);
  const allItems = useSelector((store) => store.items);

  const { getSceneSeekItems, getImageBase64 } = useScenes();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (allScenes?.length > 0 && seekScenes.length > 0) {
      console.log('seekScenes: ', seekScenes);
      //сцена
      setSceneById(allScenes?.find(scene => scene?.id === Number(sceneId)));
      //массив для точек с распознаными кусочками изображения
      arrayCroppedImg.current = seekScenes?.filter(seekScene => seekScene?.scene_id === Number(sceneId));
      //массив с id seek sceens
      arrayOfSeekSceensId.current = arrayCroppedImg.current.reduce((acc, seekScene) => {
        acc.push(seekScene.id);
        return acc
      }, []);
      console.log('arrayOfSeekSceensId.current: ', arrayOfSeekSceensId.current);
      const selectedItems = allItems.filter(item => arrayOfSeekSceensId.current.includes(item.dotId));
      console.log('user: ', user);
      console.log('selectedItems: ', selectedItems);
      if (user && arrayOfSeekSceensId.current.length > 0 && selectedItems.length === 0) {
        console.log('search seek items');
        getSceneSeekItems(arrayOfSeekSceensId.current);
        arrayOfSeekSceensId.current.forEach((dot) => {
          dispatch({ type: 'watcher/addActiveDots', payload: dot });
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allScenes, seekScenes, user])
  
  const getImg = async () => {
    const imageSrc = await getImageBase64(sceneById.direct_url);
    loadedImg.current = new Image();
    loadedImg.current.src = imageSrc;
    setTrigger(true)
  };

  useEffect(() => {
    if (sceneById) {
      getImg();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sceneById, serverUrl])

  const cropImg = (offsetWidth, offsetHeight, x, y) => {
    const coeficient = imgRef.current.width / arrayCroppedImg.current[0].image_dimension[0];
    const canvas = document.createElement('canvas');
    document.body.appendChild(canvas);
    const cvs = document.querySelector('canvas');
    cvs.style.display = 'none';
    cvs.width = offsetWidth / coeficient;
    cvs.height = offsetHeight / coeficient;
    const ctx = cvs.getContext('2d');
    ctx.drawImage(loadedImg.current, x / coeficient, y / coeficient, offsetWidth / coeficient, offsetHeight / coeficient, 0, 0, offsetWidth / coeficient, offsetHeight / coeficient);
    const dataURL = cvs.toDataURL('image/jpeg');
    document.body.removeChild(cvs);
    return dataURL;
  }

  return (
    <Flex
      p='24px 111px 90px'
      gap='30px'
      minH='calc(100vh - 90px - 98px)'
      justifyContent='center'
      minW='100vw'
    >
      <Flex
        flexDirection='column'
        gap='18px'
      >
        <Text
          fontWeight='500'
          fontSize='26px'
          color='#000000'
          lineHeight='24px'
        >
          {t('scan-scene.img-header')}
        </Text>
        <Flex
          className="image-lens"
          borderRadius='12px'
          overflow='hidden'
          position='sticky'
          height='fit-content'
          top='120px'
          width='594px'
        >
          {trigger
            ?
            <>
              <ChakraImage
                id="forLens"
                ref={imgRef}
                src={loadedImg.current.src}
                alt="Scene Image"
                draggable="false"
                pointerEvents={'none'}
                onLoad={() => setIsLoaded(true)}
                width="800px"
                userSelect={'none'}
                height="fit-content"
                filter={lensSwitch ? 'brightness(0.7)' : 'none'}
              />
              {lensSwitch
                ?
                isLoaded
                &&
                <DotsSearch
                  arrCoor={arrayCroppedImg.current}
                  imgRef={imgRef}
                  setItemId={setItemId}
                  allItems={allItems}
                  cropImg={cropImg}
                  onOpen={onOpen}
                />
                :
                <LensSearch
                  frameRef={frameRef}
                  cropImg={cropImg}
                  sceneId={sceneId}
                  coeficient={imgRef.current.width / arrayCroppedImg.current[0].image_dimension[0]}
                  image_dimension={arrayCroppedImg.current[0].image_dimension}
                  onOpen={onOpen}
                />
              }
              <Button
                zIndex={2}
                position={'absolute'}
                right='15px'
                top='15px'
                bg='white'
                borderRadius='50%'
                width='36px'
                minW='36px'
                height='36px'
                className='group'
                _hover={{ background: '#B19477' }}
                transition='background 0.2s linear'
                onClick={() => setLensSwitch((prev) => !prev)}
              >
                <ChakraImage
                  w={lensSwitch ? '16px' : '24px'}
                  maxW={lensSwitch ? '16px' : '24px'}
                  p='0'
                  _groupHover={{ filter: 'invert(1)' }}
                  _groupActive={{ transform: 'scale(0.8)' }}
                  transition='filter 0.2s linear, transform 0.2s linear'
                  src={lensSwitch ? ScanRect : ScanDots}
                />
              </Button>
            </>
            :
            <Flex
              w='100%'
              h='500px'
              justifyContent='center'
              alignItems='center'
            >
              <Loader />
            </Flex>
          }
        </Flex>
      </Flex>
      <Flex
        w='594px'
        gap='18px'
        flexDirection='column'
      >
        <Text
          fontWeight='500'
          fontSize='26px'
          color='#000000'
          lineHeight='24px'
        >
          {t('scan-scene.items-module.header')}
        </Text>
        {lensSwitch
          ?
          <>
            {itemId
              ?
              <>
                <Box
                  width='100%'
                  sx={{ columnCount: 2 }}
                >
                  <ItemsList itemId={itemId} data={allItems?.find((item) => item.dotId === itemId) || mock} />
                </Box>
                {!allItems?.find((item) => item.dotId === itemId) &&
                  <Text
                    w='60%'
                    fontWeight='400'
                    fontSize='14px'
                    color='#9b9b9b'
                    lineHeight='14px'
                  >
                    {t('scan-scene.disclaimer')}  
                  </Text>
                }
              </>
              :
              <Fade
                in
                transition={{ enter: { duration: 0.5 } }}
              >
                <ScanTutorial lensSwitch={lensSwitch} />
              </Fade>
            }
          </>
          :
          <>
            {lensDotId
              ?
              <>
                <Box
                  width='100%'
                  sx={{ columnCount: 2 }}
                >
                  <ItemsList itemId={itemId} data={allItems?.find((item) => item.dotId === lensDotId) || mock} />
                </Box>
                {!allItems?.find((item) => item.dotId === lensDotId) &&
                  <Text
                    w='60%'
                    fontWeight='400'
                    fontSize='14px'
                    color='#9b9b9b'
                    lineHeight='14px'
                  >
                    {t('scan-scene.disclaimer')}
                  </Text>
                }
              </>
              :
              <Fade
                in
                transition={{ enter: { duration: 0.5 } }}
              >
                <ScanTutorial lensSwitch={lensSwitch} />
              </Fade>
            }
          </>
        }
      </Flex>
      <ModalComponent
        isOpen={isOpen}
        onClose={onClose}
        children={<AuthModule authSwitch={true} onClose={onClose} />}
      />
    </Flex>
  );
};