import {
    //  Fade,
     Flex, 
     Image,
    //  SlideFade,
     Text 
    } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
// import { useState } from "react";
// import FeaturesList from "../Main/LastModule/FeaturesList";
// import BtnSubscribe from "../../components/Buttons/BtnSubscribe";
import Group1 from '../../components/Images/Premium/Group1.png';
import Group2 from '../../components/Images/Premium/Group2.png';
import Group3 from '../../components/Images/Premium/Group3.png';
import Group4 from '../../components/Images/Premium/Group4.png';
// import Star1 from '../../components/Images/Star1.svg';
import RectWave from '../../components/Images/Rectangle3.png';
import Temporary from "./Temporary";
// import CardForm from "./CardForm";

export default function Subscription() {
  const { t } = useTranslation();
  // const [toggle, setToggle] = useState(true);
  // const [switchForm, setSwitchForm] = useState(false);
  // const [switchForm2, setSwitchForm2] = useState(true);
  // const [mountCont, setMountCont] = useState(false);

  // const handlerToggle = () => {
  //   setToggle(prev => !prev);
  // }

  // const handlerSwitch = () => {
  //   if (mountCont) {
  //   setSwitchForm(prev => !prev);
  //   setTimeout(() => {
  //     setSwitchForm2(prev => !prev);
  //   }, 200)
  // } else {
  //   setSwitchForm2(prev => !prev);
  //   setTimeout(() => {
  //     setSwitchForm(prev => !prev);
  //   }, 200)
  // }
  //   setMountCont(prev => !prev);
  // }

  return (
    <Flex
      flexDirection='column'
      alignItems='center'
      p='43px 111px'
      minH='calc(100vh - 90px - 98px)'
    >
      <Text
       fontSize='46px'
       fontWeight='700'
       color='#000000'
       lineHeight='46px'
       letterSpacing='2px'
       mb='38px'
       zIndex='2'
      >
        {t('subscription.header')}
      </Text>
      <Flex
        w='822px'
        h='363px'
        position='relative'
      >
          <Flex
          w='822px'
          h='363px'
          bg='#f9f9f9'
          borderRadius='12px'
          zIndex='2'
          p='49px 55px'
          overflow='hidden'
        >
          <Temporary/>
          {/* <Flex
            flexDirection='column'
            maxW={switchForm ? '228px' : '354px'}
            transition='max-width 0.3s linear'
            borderRight='1px solid #e9e9e9'
            w='100%'
            h='100%'
          >
            <Flex
              w='172px'
              h='54px'
              borderRadius='30px'
              border='1px solid #00000010'
              position='relative'
              justifyContent='space-between'
              p='18px 26px'
              onClick={handlerToggle}
            >
              <Text
                color='#000000'
                fontSize='16px'
                lineHeight='18px'
                fontWeight='700'
                transition='color 0.2s ease-in-out'
              >
                {t('subscription.card.toggle-month')}
              </Text>
              <Text
                color='#000000'
                fontSize='16px'
                lineHeight='18px'
                fontWeight='700'
                transition='color 0.2s ease-in-out'
              >
                {t('subscription.card.toggle-year')}
              </Text>
              <Flex
                position='absolute'
                top='0'
                left={toggle ? '0px' : '86px'}
                w={toggle ? '104px' : '84px'}
                h='54px'
                backdropFilter='invert(1)'
                borderRadius='30px'
                transition='all 0.2s linear'
              >
              </Flex>
            </Flex>
            <Flex
              mt='24px'
              ml='2px'
              alignItems='flex-end'
              gap='10px'
            >
              <SlideFade in>
                <Text
                  fontSize='72px'
                  lineHeight='80px'
                  fontWeight='700'
                  color='#000000'
                  >
                  {toggle ? '$29' : '$129' }
                </Text>
              </SlideFade>
              <SlideFade in>
                <Text
                  fontSize='20px'
                  lineHeight='20px'
                  fontWeight='400'
                  color='#00000060'
                  mb='15px'
                  >
                  {toggle ? `${t('subscription.card.per-month')}` : `${t('subscription.card.per-year')}`}
                </Text>
              </SlideFade>
            </Flex>
            <SlideFade 
              in={!toggle}
              transition={{exit:{delay: 0.2, duration: 0.3}, enter: {duration: 0.3}}}
              >
              <Text
                fontSize='20px'
                lineHeight='20px'
                fontWeight='400'
                color='#00000060'
                mt='10px'
                mb='39px'
                >
                {t('subscription.card.year-per-month')}
              </Text>
            </SlideFade>
            <SlideFade 
              in={!toggle}
              transition={{exit:{duration: 0.3}, enter: {delay: 0.2, duration: 0.3}}}
              >
              <Flex
                w='166px'
                h='36px'
                borderRadius='42px'
                bg='#B194771A'
                p='9px 18px'
                gap='8px'
                >
                <Image src={Star1}/>
                <Text
                  fontSize='14px'
                  lineHeight='18px'
                  fontWeight='700'
                  color='#B19477'
                >
                  {t('subscription.card.badge')}  
                </Text>
              </Flex>
            </SlideFade>
          </Flex>
          <Fade 
            in={switchForm} 
            unmountOnExit
            transition={{exit:{duration: 0.2}, enter: {duration: 0.4}}}
          >
            <CardForm handlerSwitch={handlerSwitch} toggle={toggle} />
          </Fade>
          <Fade
            in={switchForm2}
            unmountOnExit
            transition={{exit:{duration: 0.2}, enter: {duration: 0.4}}}
          >
            <Flex 
              flexDirection='column'
              minW='355px'
              position='relative'
              pl='40px'
              h='100%'
              justifyContent='space-between'
            >
              <FeaturesList />
              <BtnSubscribe handler={handlerSwitch} />
            </Flex>
          </Fade> */}
        </Flex>
        <Image 
          src={Group1}
          position='absolute'
          top='-126px'
          left='-183px'
          zIndex='1'
        />
        <Image 
          src={Group2}
          position='absolute'
          top='-155px'
          right='-243px'
          zIndex='1'
        />
        <Image 
          src={Group3}
          position='absolute'
          bottom='-127px'
          left='-192px'
          zIndex='1'
        />
        <Image 
          src={Group4}
          position='absolute'
          bottom='-185px'
          right='-285px'
          zIndex='1'
        />
      </Flex>
      <Flex 
        position='absolute'
        top='0'
        zIndex='0'
        minW='100vw'
        minH='100vh'
        backgroundSize='cover'
        backgroundImage={`linear-gradient(90deg, #FAE4CE40, #D2965340), url(${RectWave})`}
        // backgroundImage={`linear-gradient(180deg, #FACECE60, #5395D260)`}
      />
    </Flex>
  )
}