import { Flex, Image, Text } from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';
import Armchair2 from '../../../components/Images/Armchair2.png'
import Armchair3 from '../../../components/Images/Armchair3.png'
import Table from '../../../components/Images/Table.svg'
import Lamp from '../../../components/Images/Lamp.svg'
import CursorSvg from '../../../components/Images/CursorSvg.svg'
import UploadModule from "../../Main/ScanModule/UploadModule";

export default function MidModule() {
  const { t } = useTranslation(); 

  return (
        <Flex
          backgroundImage={`linear-gradient(90deg, #FAE4CE40, #D2965340)`}
          w='1218px'
          h='407px'
          borderRadius='40px'
          m='43px 0 93px'

        >
          <Flex
            position='relative'
            w='290px'
          >
            <Image 
              top='58px'
              left='72px'
              position='absolute'
              src={Lamp} 
            />
            <Image 
              top='84px'
              left='-30px'
              position='absolute'
              src={Armchair3} 
            />
            <Image 
              top='220px'
              left='170px'
              position='absolute'
              src={CursorSvg} 
            />
          </Flex>
          <Flex
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
            w='640px'
          >
            <Text
              fontSize='56px'
              lineHeight='66px'
              fontWeight='500'
              color='#000000'
              textAlign='center'
              mb='16px'
            >
              {t("scenes.scan-module.header")}
            </Text>
            <Text
              fontSize='18px'
              lineHeight='30px'
              fontWeight='400'
              color='#00000060'
              textAlign='center'
              mb='33px'
            >
              {t("scenes.scan-module.title")}
            </Text>
            <UploadModule />
          </Flex>
          <Flex
            position='relative'
            w='290px'
          >
            <Image 
              top='47px'
              left='17px'
              position='absolute'
              maxW='398px'
              src={Armchair2} 
            />
            <Image 
              top='147px'
              left='-52px'
              position='absolute'
              src={Table} 
            />
            <Image 
              top='270px'
              left='190px'
              position='absolute'
              src={CursorSvg} 
            />
          </Flex>
        </Flex>
    )
}