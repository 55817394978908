import { createSlice } from '@reduxjs/toolkit';

const seekScenesSlice = createSlice({
  name: 'seekScenes',
  initialState: [],
  reducers: {
    setSeekScenes: (state, action) => {
      return action.payload;
    },
    addSeekScenes: (state, action) => {
      state.push(...action.payload);
      return state;
    },
    addNewSeekScene: (state, action) => {
      state.push(action.payload);
      return state;
    },
  },
});

export const { setSeekScenes, addSeekScenes, addNewSeekScene } = seekScenesSlice.actions;

export default seekScenesSlice.reducer;
