import { useSelector } from "react-redux";
import { Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import ViewScenes from "../../components/Scenes/ViewScenes";
import ScenesScanModule from "./ScanModule/ScenesScanModule";
import BtnLoadScenes from "../../components/Buttons/BtnLoadScenes";

export default function Scenes() {
  const { t } = useTranslation();
  const allScenes = useSelector((store) => store.scenes);

  return (
    <Flex
      flexDirection='column'
      alignItems='center'
      p='43px 111px'
      minH='calc(100vh - 90px - 98px)'
    >
      <ScenesScanModule />
      <Text
       fontSize='46px'
       fontWeight='700'
       color='#000000'
       lineHeight='46px'
       letterSpacing='2px'
       mb='45px'
      >
        {t('scenes.header')}
      </Text>
      <Flex
        w='1218px'
      >
        {allScenes?.length > 0 &&
          <ViewScenes allScenes={allScenes} />
        }
      </Flex>
      <BtnLoadScenes />
    </Flex>
  )
}