import {
  Box,
  Image as ChakraImage,
  Fade,
  Flex,
  Icon,
  Input,
  Skeleton,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import {
  useSelector
} from "react-redux";
import { useNavigate } from "react-router-dom";
import PlusIcon from "../Images/PlusIcon";
import { useScenes } from "../../services/hooks/sceneService";
import ModalComponent from "../ModalComponent";
import AuthModule from "../Auth/AuthModule";
import bgImg from "../Images/gradientBG.png"
// import { useTranslation } from "react-i18next";

export default function ViewScenes({ allScenes }) {
  const navigate = useNavigate();
  const user = useSelector((store) => store.user);
  const seekScenes = useSelector((store) => store.seekScenes);
  const scenesSrc = useSelector((store) => store.scenesSrc);
  const [arrSrc, setArrSrc] = useState([]);
  const inputRef = useRef();
  const blockRef = useRef();
  // const isRun = useRef(false);
  const [scrollOn, setScrollOn] = useState(0);
  const toast = useToast();
  const { handlerNewScene, getImageBase64 } = useScenes();
  const { isOpen, onOpen, onClose } = useDisclosure();
  // const { t } = useTranslation();

  useEffect(() => {
    const handleScroll = () => {
      setScrollOn(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
  const fetchImages = async () => {
    await Promise.all(allScenes?.map(async (scene) => {
      if (scenesSrc.find(src => src.scene_id === scene.id)) return;
      const imageSrc = await getImageBase64(scene.direct_url);
      setArrSrc(prev => [{ scene_id: scene.id, src: imageSrc }, ...prev])
    }));
  }
  
  useEffect(() => {
    // if (isRun.current) return;
    fetchImages();
  
    // isRun.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allScenes]);

  //рисуем точки
  const dots = (arrCoor) => {
  
    const coeficient = (blockRef?.current?.offsetWidth - 32) / 3 / arrCoor[0]?.image_dimension[0];
    
    return arrCoor?.map((coor, index) => (
      <Fade
        in
        transition={{ enter: { duration: 0.3, delay: index * 0.05 } }}
        key={`dot${coor.id}`}
      >
        <Box
          tabIndex={index}
          data-itemid={coor.id}
          position={'absolute'}
          zIndex={2}
          style={{
            left: `${(coor.bbox_center[0] - 10) * coeficient}px`,
            top: `${(coor.bbox_center[1] - 10) * coeficient}px`,
            width: '17px',
            height: '17px',
            background: '#B19477',
            border: '5px solid white',
            borderRadius: '50%',
          }}
        />
      </Fade>
    ));
  }

  // карточка +
  const handlerUpload = (event) => {
    let newImg = null;
    const file = event.target.files[0];
    const inputData = new FormData();
    inputData.append('scene', file);
    newImg = inputData;

    handlerNewScene({ inputRef, newImg, toast });
  }

  const handlerCheckUser = () => {
    if (user) {
      inputRef.current.click()
    } else {
      onOpen();
    }
  }

  return (
    <Box
      w='100%'
      sx={{ columnCount: 3 }}
      ref={blockRef}
    >
      {allScenes?.length > 0 && allScenes?.map((scene, index) => {
        const arrayCroppedImg = seekScenes?.filter(seekScene => seekScene?.scene_id === scene.id);
        const imgSrc = arrSrc.find(img => img.scene_id === scene.id)?.src;

        return (
          <Box
            position='relative'
            key={`scene${scene.id}`}
            onClick={() => navigate(`/scenes/show/${scene.id}`)}
          >
           
            <Skeleton
              borderRadius='12px'
              mb='12px'
              aspectRatio='3/2'
              isLoaded={!!imgSrc}
              fadeDuration={index * 0.1}
              startColor='#f2efec'
              endColor='#d7c2ac'
            >
              <ChakraImage
                w='100%'
                borderRadius='12px'
                mb='12px'
                src={imgSrc || bgImg}
              />
              {scrollOn > (blockRef?.current?.offsetTop - 700) && dots(arrayCroppedImg)}
            </Skeleton>
          </Box>
        )
      })}
      <Flex
        aspectRatio='3/2'
        borderRadius='12px'
        position='relative'
        justifyContent='center'
        alignItems='center'
        overflow='hidden'
        cursor='pointer'
        className='group'
        onClick={handlerCheckUser}
      >
        <Input
          name="scene"
          id="scene"
          type="file"
          ref={inputRef}
          accept="image/*"
          display="none"
          onChange={handlerUpload}
        />
        <ChakraImage
          position='absolute'
          top='0'
          left='0'
          src={bgImg}
          w='100%'
          h='100%'
        />
        <Box
          w='66px'
          h='66px'
          p='16px'
          color='#748D63'
          bg='#ffffff'
          zIndex='1'
          borderRadius='50%'
          transition='all 0.2s linear'
          _groupHover={{ padding: '14px' }}
        >
          <Icon
            as={PlusIcon}
          />
        </Box>
    </Flex>
    <ModalComponent
        isOpen={isOpen}
        onClose={onClose}
        children={<AuthModule authSwitch={true}  onClose={onClose} />}
      />
    </Box>
  )
};