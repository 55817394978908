import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCarts } from "./cartService";
import { useScenes } from "./sceneService";

const useAuth = () => {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const { getCart } = useCarts();
  const { getUserScenes } = useScenes();
  const scenes = useSelector(store => store.scenes);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handlerAuthLogin = async (formData) => {
    try {
      const response = await fetch(`${serverUrl}/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(formData)
      });
      if (!response.ok) {
        throw new Error(response.status);
      }

      const data = await response.json();

      if (!data?.error) {
        dispatch({ type: 'user/setUser', payload: data });
        getCart(data);
        console.log('data: ', data);
        getUserScenes(data.id, scenes); 
        // navigate('/');
        window.location.href = '/';
        return data;
      } else {
        return data
      }
    } catch (error) {
      return {error};
    }
  };

  const handlerAuthReg = async (formData) => {
    try {
      const response = await fetch(`${serverUrl}/auth/registration`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(formData)
      });
      if (!response.ok) {
        throw new Error(response.status);
      }

      const data = await response.json();

      if (!data?.error) {
        dispatch({ type: 'user/setUser', payload: data })
        // navigate('/');
        window.location.href = '/';
        return data;
      }
    } catch (error) {
      return { error };
    }
  }

  const checkAuth = async (dataArr) => {
    try {
      const response = await fetch(`${serverUrl}/auth/get-user`, {
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error(response.error);
      }

      const data = await response.json();

      if (!data.message) {
        dispatch({ type: 'user/setUser', payload: data });
        getCart(data);
        getUserScenes(data.id, dataArr);
      }
    } catch (err) {
      return;
    }
  }

  const handlerAuthLogOut = () => {
    const response = fetch(`${serverUrl}/auth/logout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
    })
    response
      .then(response => {
        if (response.ok) {
          //очищаем store
          dispatch({ type: 'user/clearUser' });
          dispatch({ type: 'cart/clearCart' });
          dispatch({ type: 'items/clearItems' });
          dispatch({ type: 'orders/clearOrders' });
          // Перенаправление на страницу входа или главную страницу
          navigate('/');
        } else {
          // Обработка ошибок выхода из системы
          console.error('Logout failed');
        }
      })
      .catch(error => {
        // Обработка ошибок сети
        console.error('Network error:', error);
      });
  }

  const handlerUserSubscribe = (duration) => {
    const response = fetch(`${serverUrl}/auth/role-update`, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ duration: duration }),
      credentials: 'include'
    })

    const res = response
      .then(res => {
        if (!res.ok) {
          throw new Error(`Server error, status: ${res.status}`);
        }
        dispatch({ type: 'user/updateRole', payload: 'member' });
        return true;
      })
      .catch(error => {
        console.log(error);
        return false;
      })
    
    return res
  }

  return { handlerUserSubscribe, handlerAuthLogin, handlerAuthLogOut, checkAuth, handlerAuthReg };
};

export { useAuth };