import { Button, Image, keyframes } from "@chakra-ui/react";
import FeedbackImg from '../Images/FeedbackImg.png';

export default function BtnFeedback({ onOpen }) {
  const pulse = keyframes`
  0% {
    outline-offset: 0px;
  }
  50% {
    outline-offset: 5px;
  }
  100% {
    outline-offset: 0px;
  }
  `

  return (
    <Button
      zIndex='3'
      position='fixed'
      right='30px'
      bottom='30px'
      background='#748d63'
      width='50px'
      height='50px'
      transition='all 0.2s ease-in-out'
      overflow='hidden'
      borderRadius='50%'
      outline='2px solid #8aa876'
      animation={`${pulse} 1s infinite`}
      p='0'
      _hover={{transform: 'scale(1.1)' }}
      _active={{transform: 'scale(0.9)'}}
      onClick={onOpen}
    >
      <Image 
        w='20px'
        maxW='20px'
        p='0'
        src={FeedbackImg} 
        />
    </Button> 
  )
}