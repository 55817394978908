import { configureStore } from '@reduxjs/toolkit';
import userReducer from './userSlice';
import sceneReducer from './sceneSlice';
import itemsReducer from './itemsSlice';
import cartReducer from './cartSlice';
import ordersReducer from './ordersSlice';
import seekScenesReducer from './seekScenesSlice';
import watcherReducer from './watcherSlice';
import scenesSrcReducer from './scenesSrcSlice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    scenes: sceneReducer,
    items: itemsReducer,
    cart: cartReducer,
    orders: ordersReducer,
    seekScenes: seekScenesReducer,
    watcher: watcherReducer,
    scenesSrc: scenesSrcReducer,
  },
});

