const LogoutIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path id="Vector" d="M6.56055 14.62L4.00055 12.06L6.56055 9.5" 
    // strokeMiterlimit="10" 
    strokeLinecap="round" strokeLinejoin="round"/>
    <path id="Vector_2" d="M14.2402 12.06H4.07023"
    //  strokeMiterlimit="10" 
     strokeLinecap="round" strokeLinejoin="round"/>
    <path id="Vector_3" d="M12.2402 20C16.6602 20 20.2402 17 20.2402 12C20.2402 7 16.6602 4 12.2402 4"
    //  strokeMiterlimit="10" 
     strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}
  
export default LogoutIcon;
