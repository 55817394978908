import { useDispatch, useSelector } from "react-redux";

const useCarts = () => {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const userCart = useSelector(store => store.cart);
  const user = useSelector(store => store.user);
  const dispatch = useDispatch();
  
  const getCart = (data) => {
    fetch(`${serverUrl}/cart/user-cart?id=${data.id}`, {
      credentials: 'include'
    });
  }

  const addToCart = async (item) => {
    let itemExists = false;
    const newCart = userCart.map((el) => {
      if (el.id === item.id) {
        itemExists = true;
        fetch(`${serverUrl}/cart/user-cart?id=${user.id}`, {
          method: 'PATCH',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ external_id: el.external_id, quantity: el.quantity + 1 })
        });
        return { ...el, quantity: el.quantity + 1 };
      }
      return el;
    });
    if (!itemExists) {
      newCart.push({ ...item, quantity: 1 });
      fetch(`${serverUrl}/cart/user-cart?id=${user.id}`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ external_id: item.external_id, quantity: 1 })
      });
    }
    dispatch({ type: 'cart/setCart', payload: newCart });
  }

  const removeFromCart = (itemId) => {
    const newCart = userCart.map(item => {
      if (item.id === itemId) {
        if (item.quantity > 1) {
          fetch(`${serverUrl}/cart/user-cart?id=${user.id}`, {
            method: 'PATCH',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ external_id: item.external_id, quantity: item.quantity - 1 })
          });
          return { ...item, quantity: item.quantity - 1 };
        } else {
          fetch(`${serverUrl}/cart/user-cart?id=${user.id}`, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ external_id: item.external_id })
          });
          return null;
        };
      } else {
        return item;
      };
    }).filter(item => item !== null);
    dispatch({ type: 'cart/setCart', payload: newCart });
  };

  return { getCart, addToCart, removeFromCart };
};

export { useCarts };