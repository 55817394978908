import { createSlice } from '@reduxjs/toolkit';

const scenesSrcSlice = createSlice({
  name: 'scenesSrc',
  initialState: [],
  reducers: {
    setScenesSrc: (state, action) => {
      return action.payload;
    },
    addScenesSrc: (state, action) => {
      state.push(action.payload);
      return state;
    },
  },
});

export const { setScenesSrc, addScenesSrc } = scenesSrcSlice.actions;

export default scenesSrcSlice.reducer;
