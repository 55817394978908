import { createSlice } from '@reduxjs/toolkit';

const itemsSlice = createSlice({
  name: 'items',
  initialState: [],
  reducers: {
    setItems: (state, action) => {
      return action.payload;
    },
    addItems: (state, action) => {
      state.push(action.payload);
      return state;
    },
    clearItems: () => {
      return [];
    },
  },
});

export const { addItem, clearItems, setItems } = itemsSlice.actions;

export default itemsSlice.reducer;
