import { Button, Fade, Flex, FormControl, FormLabel, Icon, Input, InputGroup, InputRightElement, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ViewIcon from "../../../components/Images/Profile/viewIcon";
import ViewIconOff from "../../../components/Images/Profile/viewIconOff";
import { useSelector } from "react-redux";

export default function AccountSection() {
  const { t } = useTranslation();
  const user = useSelector(store => store.user);
  const [toggleEdit, setToggleEdit] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <Text
        fontSize='28px'
        fontWeight='500'
        lineHeight='36px'
        color='#000000'
      >
        {t('profile.side-section.account')}
      </Text>
      <Fade
       in
       transition={{ enter: { duration: 0.5 } }}
      >
      <Flex
        w='100%'
        h='max-content'
        bg='#ffffff'
        borderRadius='12px'
        p='33px 31px'
        flexDirection='column'
        position='relative'
      >
        <Text
          fontSize='26px'
          fontWeight='400'
          lineHeight='28px'
          color='#000000'
          mb='20px'
        >
          {t('profile.account-section.header')}
        </Text>
        <Flex
          justifyContent='flex-start'
          gap='55px'
          h='fit-content'
        >
          <FormControl
            w='244px'
            isReadOnly={!toggleEdit}
          >
            <FormLabel
              fontSize='14px'
              fontWeight='400'
              lineHeight='18px'
            >
              {t('profile.account-section.name')}
            </FormLabel>
            <Input 
              type='email' 
              placeholder={user.nickname}
              h='48px'
              borderRadius='12px'
            />
          </FormControl>
          <FormControl
            w='244px'
            isReadOnly={!toggleEdit}
          >
            <FormLabel
              fontSize='14px'
              fontWeight='400'
              lineHeight='18px'
            >
              {t('profile.account-section.email')}
            </FormLabel>
            <Input 
              placeholder={user.mail}
              type='email'
              h='48px'
              borderRadius='12px'
            />
          </FormControl>
          <Fade
            in={toggleEdit}
            transition={{ enter: { duration: 0.2 }, exit: { duration: 0.2 } }}
          >
            <FormControl 
              id="password"
              w='244px'
            >
              <FormLabel
                fontSize='14px'
                fontWeight='400'
                lineHeight='18px'
              >
                {t('profile.account-section.password')}
              </FormLabel>
              <InputGroup>
                <Input 
                  h='48px'
                  type={showPassword ? 'text' : 'password'}
                  borderRadius='12px'
                />
                <InputRightElement 
                  h='full'
                  w='48px'
                >
                  <Button
                    variant='ghost'
                    onClick={() => setShowPassword((prev) => !prev)}
                    p='14px'
                    w='full'
                    h='full'
                    color='#B19477'
                    _focus={{ borderColor: '#B19477'}}
                    _hover={{ backgroundColor: '#B1947733' }}
                    _active={{ backgroundColor: '#B1947740' }} 
                  >
                    {showPassword ? <Icon as={ViewIconOff} /> : <Icon as={ViewIcon} />}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
          </Fade>
        </Flex>
        <Button
          w='fit-content'
          h='48px'
          color='#000000'
          fontSize='16px'
          fontWeight='500'
          borderRadius='12px'
          lineHeight='18px'
          textAlign='center'
          position='absolute'
          top='24px'
          right='24px'
          p="18px 24px"
          bg='#ffffff'
          border='1px solid #e9e9e9'
          onClick={() => setToggleEdit(prev => !prev)}
          _focus={{ borderColor: '#B19477'}}
          _hover={{ backgroundColor: '#B1947733' }}
          _active={{ backgroundColor: '#B1947740' }}
        >
          {t('profile.account-section.button-edit')}
        </Button>
      </Flex>
      </Fade>
    </>
  )
}