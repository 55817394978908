import { Button, Image, keyframes } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import RefreshSvg from '../Images/RefreshSvg.svg'
import { useState } from "react";
import { useScenes } from "../../services/hooks/sceneService";

export default function BtnLoadScenes() {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false)
  const {getMoreScenes} = useScenes();

   const rotateLoad = keyframes({
      '0%': {
          transform: 'rotate(0deg)',
      },
      '100%': {
          transform: 'rotate(-360deg)',
      },
  })

  const handlerLoadMore = async () => {
    if (isLoading) return;
    setLoading(true);
    const response = await getMoreScenes()
    setLoading(!response);
  }
  
  return (
    <Button
      minW='263px'
      h='54px'
      color='#ffffff'
      fontSize='16px'
      fontWeight='700'
      borderRadius='12px'
      mt='54px'
      p="18px 24px"
      bg='#000000'
      onClick={handlerLoadMore}
      _hover={{ backgroundColor: '#262626' }}
      _active={{ backgroundColor: '#333333' }}
      _focus={{ backgroundColor: '#B19477' }}
    >
      <Image 
        src={RefreshSvg}
        mr='8px'
        transform='rotate(-360deg)'
        animation={isLoading ? `${rotateLoad} 0.4s linear infinite` : 'none' }
      />
        {t('scenes.button')}
    </Button>
  )
}