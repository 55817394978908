import { Button, Flex, Image, useDisclosure } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import Armchair from "../../components/Images/LastModuleImg.png";
import ModalComponent from "../../components/ModalComponent";
import Activated from "./Activated";
import { useAuth } from "../../services/hooks/authService";
import FeaturesList from "../Main/LastModule/FeaturesList";

export default function Temporary() {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { handlerUserSubscribe } = useAuth()

  const handlerFormSubmit = async () => {
    const response = await handlerUserSubscribe('month')
    response && onOpen(); //открытие модалки
  }

  return (
    <>
      <Flex
        flexDirection='column'
        borderRight='1px solid #e9e9e9'
        w='50%'
        h='100%'
      >
        <Image
          w='350px'
          position='absolute'
          top='35px'
          left='40px'
          src={Armchair}
        />
      </Flex>
      <Flex
        flexDirection='column'
        w='50%'
        h='100%'
        alignItems='center'
        justifyContent='space-between'
      >
        <FeaturesList />
        <Button
          w='211px'
          h='54px'
          color='#ffffff'
          fontSize='16px'
          fontWeight='700'
          borderRadius='12px'
          onClick={handlerFormSubmit}
          p="18px 24px"
          bg='#000000'
          _hover={{ backgroundColor: '#262626' }}
          _active={{ backgroundColor: '#333333' }}
          _focus={{ backgroundColor: '#B19477' }}
        >
          {t('subscription.card.button')}
        </Button>
      </Flex>
      <ModalComponent
        isOpen={isOpen}
        onClose={onClose}
        children={<Activated onClose={onClose} />}
      />
    </>
  );
};

