import { useRef } from "react";
import {
  Button,
  Flex,
  Image,
  Input,
  Text,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useScenes } from "../../../services/hooks/sceneService";
import UploadIcon from '../../../components/Images/UploadIcon.svg';
import { useSelector } from "react-redux";
import ModalComponent from "../../../components/ModalComponent";
import AuthModule from "../../../components/Auth/AuthModule";

export default function UploadModule() {
  const inputRef = useRef();
  const toast = useToast();
  const { t } = useTranslation();
  const { handlerNewScene } = useScenes();
  const user = useSelector(store => store.user)
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handlerCheckUser = () => {
    if (user) {
      inputRef.current.click()
    } else {
      onOpen();
    }
  }

  const handlerUpload = (event) => {
    let newImg = null;
    const file = event.target.files[0];
    const inputData = new FormData();
    inputData.append('scene', file);
    newImg = inputData;

    handlerNewScene({ inputRef, newImg, toast })
  };

  return (
    <Flex
      w='553px'
      h='80px'
      className='group'
      borderRadius='40px'
      justifyContent='space-between'
      alignItems='center'
      bg='#FFFFFF'
      p='0px 39px 0px 44px'
      transform='translateX(-7px)'
      cursor='pointer'
      onClick={handlerCheckUser}
    >
      <Image
        src={UploadIcon}
      />
      <Text
        fontWeight='400'
        fontSize='18px'
        color='#00000060'
        w='100%'
        p='17px'
      >
        {t('main.scan-module.input')}
      </Text>
      <Button
        borderRadius='45px'
        bg='#000000'
        color='#FFFFFF'
        w='105px'
        h='50px'
        fontSize='16px'
        fontWeight='500'
        _groupHover={{ backgroundColor: '#262626' }}
        _groupActive={{ backgroundColor: '#333333' }}
        _groupFocus={{ backgroundColor: '#B19477' }}
      >
        {t('main.scan-module.button')}
      </Button>
      <Input
        name="scene"
        id="scene"
        type="file"
        ref={inputRef}
        accept="image/*"
        display="none"
        onChange={handlerUpload}
      />
       <ModalComponent
        isOpen={isOpen}
        onClose={onClose}
        children={<AuthModule authSwitch={true}  onClose={onClose} />}
      />
    </Flex>
  )
}