import { Button, useDisclosure } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import ModalComponent from "../ModalComponent";
import AuthModule from "../Auth/AuthModule";

export default function BtnRegistration() {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  
  return (
    <>
      <Button
        minW='138px'
        h='54px'
        color='#ffffff'
        fontSize='16px'
        fontWeight='700'
        borderRadius='12px'
        mr='5px'
        p="18px 24px"
        bg='#000000'
        onClick={onOpen}
        _hover={{ backgroundColor: '#262626' }}
        _active={{ backgroundColor: '#333333' }}
        _focus={{ backgroundColor: '#B19477' }}
      >
        {t('nav.registration')}
      </Button>
      <ModalComponent
        isOpen={isOpen}
        onClose={onClose}
        children={<AuthModule authSwitch={false} onClose={onClose} />}
      />
    </>
  )
}