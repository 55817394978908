import { 
    Flex, 
    Image, 
    Text 
} from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';
import BtnGoPremium from "../../../components/Buttons/BtnGoPremium.jsx";
import LastModuleImg from '../../../components/Images/LastModuleImg.png';
import FeaturesList from "./FeaturesList.jsx";
import { useSelector } from "react-redux";
import BtnRegistration from "../../../components/Buttons/BtnRegistration.jsx";
import BtnLogin from "../../../components/Buttons/BtnLogin.jsx";

export default function LastModule() {
  const { t } = useTranslation(); 
  const user = useSelector((store) => store.user)

  return (
        <Flex
          backgroundImage={`linear-gradient(90deg, #FAE4CE40, #D2965340)`}
          // background='linear-gradient(135deg, #FACECE60, #5395D260)'
          w='100vw'
          h='620px'
          mb='90px'
          justifyContent='center'
        >
          <Flex
            position='relative'
            w='609px'
          >
            <Image 
              top='78px'
              left='-19px'
              position='absolute'
              src={LastModuleImg} 
            />
          </Flex>
          <Flex
            flexDirection='column'
            justifyContent='center'
            alignItems='flex-start'
            gap='46px'
            w='609px'
          >
            <Text
              fontSize='36px'
              lineHeight='46px'
              fontWeight='700'
              color='#000000'
            >
              {t("main.last-module.header")}
            </Text>
            <FeaturesList />
            {user ?
            <BtnGoPremium />
            :
            <Flex
              gap='23px'
            >
              <BtnRegistration />
              <BtnLogin />
            </Flex>
            }
          </Flex>
        </Flex>
    )
}