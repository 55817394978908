import { Avatar, Flex, Icon, Text, useDisclosure } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import UserAvatar from "../../../components/Images/UserAvatar";
import BtnProfile from "../../../components/Buttons/BtnProfile";
import MyScenesIcon from "../../../components/Images/Profile/myScenesIcon";
import LogoutIcon from "../../../components/Images/Profile/logoutIcon";
import BoxIcon from "../../../components/Images/Profile/boxIcon";
import BitcoinCard from "../../../components/Images/Profile/bitcoinCard";
import ProfileIcon from "../../../components/Images/Profile/profileIcon";
import ModalComponent from "../../../components/ModalComponent";
import LogoutModal from "./LogoutModal";

export default function SideSection() {
  const user = useSelector((store) => store.user);
  const { isOpen, onOpen, onClose } = useDisclosure()
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handlerSwitchSection = (event) => {
    navigate(`/profile#${event.currentTarget.id}`);
  }

  return (
    <Flex
      w='282px'
      bg='#ffffff'
      borderRadius='12px'
      p='30px 18px'
      flexDirection='column'
    >
      <Flex
        h='56px'
        gap='16px'
        pl='8px'
        mb='36px'
      >
        <Flex
          w='56px'
          borderRadius='50%'
          justifyContent='center'
          alignItems='center'
          bg='none'
          color='#B19477'
          border='1px solid #B1947760'
        >
          {user?.avatar
            ?
              <Avatar 
                w='56px'
                h='56px'
                bg='none'
                src={user?.avatar}
              />
            :
              <Icon
                as={UserAvatar}
              />
          }
        </Flex>
        <Flex
          flexDirection='column'
          justifyContent='center'
          gap='4px'
        >
          <Text
            fontSize='18px'
            fontWeight='500'
            lineHeight='20px'
            color='#000000'
          >
              {user.nickname}
          </Text>
          <Text
            fontSize='14px'
            fontWeight='400'
            lineHeight='20px'
            color='#333333'
          >
              {user.mail}
          </Text>
        </Flex>
      </Flex>
      <Flex
        flexDirection='column'
      >
        <BtnProfile 
          handlerSwitchSection={handlerSwitchSection} 
          iconPic={ProfileIcon} 
          title={t('profile.side-section.account')}
          sectionId={'account'}
        />
        <BtnProfile 
          handlerSwitchSection={handlerSwitchSection} 
          iconPic={MyScenesIcon} 
          title={t('profile.side-section.my-scenes')}
          sectionId={'scenes'}
        />
        <BtnProfile 
          handlerSwitchSection={handlerSwitchSection} 
          iconPic={BitcoinCard} 
          title={t('profile.side-section.my-subscription')}
          sectionId={'membership'}
        />
        <BtnProfile 
          handlerSwitchSection={handlerSwitchSection} 
          iconPic={BoxIcon} 
          title={t('profile.side-section.my-orders')}
          sectionId={'orders'}
        />
        <BtnProfile 
          handlerSwitchSection={() => onOpen()} 
          iconPic={LogoutIcon} 
          title={t('profile.side-section.logout')}
          sectionId={'logout'}
        />
      </Flex>
      <ModalComponent 
        isOpen={isOpen} 
        onClose={onClose}
        children={ <LogoutModal onClose={onClose} />}
      />
    </Flex>
  )
}