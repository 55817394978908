import React, { useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { 
  Avatar, 
  Button, 
  Flex, 
  Icon, 
  Image, 
  Link, 
  Menu, 
  MenuButton, 
  MenuList, 
  Text, 
  keyframes
 } from '@chakra-ui/react';
import Cart from '../Images/Cart.js'; 
import UserAvatar from '../Images/UserAvatar.js';
import Arrow from '../../components/Images/Arrow.svg';
import BtnRegistration from '../Buttons/BtnRegistration';
import BtnLogin from '../Buttons/BtnLogin';
import BtnPremium from '../Buttons/BtnPremium';
import MyCart from '../Cart/MyCart';

export default function NavBar() {
  const user = useSelector((store) => store.user);
  const cartArr = useSelector((store) => store.cart);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [arrLang, setArrLang] = useState([{ lang: 'EN', value: 'en' }, { lang: 'RU', value: 'ru' }, { lang: 'KZ', value: 'kz' }]);
  const locationPath = useLocation();
  const marqueeRef = useRef();

  const handlerChangeLang = (event) => {
    setArrLang((prev) => {
      const selectedIndex = prev.findIndex((lang) => lang.value === event.target.dataset.value);
      if (selectedIndex === 0) {
        return prev;
      }
      const newArr = [
        prev[selectedIndex],
        ...prev.slice(selectedIndex + 1),
        ...prev.slice(0, selectedIndex),
      ];
  
      return newArr;
    });
    i18n.changeLanguage(event.target.dataset.value);
  }

  const shine = keyframes({
    '0%': {
      backgroundPosition: '-300% 0'
    },
    '100%': {
      backgroundPosition: '300% 0'
    },
  })

  const marquee = keyframes({
    '0%': {
      transform: 'translateX(100%)'
    },
    '100%': {
      transform: 'translateX(-100%)'
    }
  });

  const qty = () => {
    let quantity = 0;
    cartArr.forEach(item => {
      quantity += item.quantity;
    });
    return quantity;
  }

  return (
    <Flex
      h='90px'
      w='100%'
      justifyContent='space-between'
      alignItems='center'
      p='0 111px'
      zIndex={100}
      position='sticky'
      top='0'
      background={locationPath.pathname !== '/membership' ? '#f9f9f9' : 'none'}
    >
      <Image
        src="/Logo.svg"
        alt="MyTerior logo"
        cursor='pointer'
        onClick={() => navigate('/')}
      />
      <Text
        position='absolute'
        top='35px'
        left='250px'
        color='#000000'
        fontSize='14px'
        lineHeight='14px'
        fontWeight='700'
        textAlign='center'
        zIndex='1'
      >
        Beta
      </Text>
      <Flex
        gap="47px"
      >
        <Link
          fontSize='18px'
          color='#000000'
          fontWeight='500'
          bg='none'
          _hover={{ color: '#B19477' }}
          href='/'
          onClick={(e) => { e.preventDefault(); navigate('/'); }}
        >
          {t('nav.main')}
        </Link>
        <Link
          fontSize='18px'
          color='#000000'
          fontWeight='500'
          bg='none'
          href='/scenes'
          _hover={{ color: '#B19477' }}
          onClick={(e) => { e.preventDefault(); navigate('/scenes'); }}
        >
          {t('nav.scenes')}
        </Link>
        <Link
          fontSize='18px'
          color='#000000'
          fontWeight='500'
          bg='none'
          href='/catalog'
          _hover={{ color: '#B19477' }}
          onClick={(e) => { e.preventDefault(); navigate('/catalog'); }}
        >
          {t('nav.catalog')}
        </Link>
      </Flex>
      <Flex
        gap='18px'
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Flex
          flexDirection='column'
          position='relative'
          className='group'
          w='96px'
          h='54px'
          p='19px 25px'
          borderRadius='12px'
          gap='10px'
          overflow='hidden'
          zIndex='1'
          _hover={{
            border: '1px solid #0000001A',
            background: '#ffffff',
            top: '28px',
            height: 'auto',
            transition: 'all 0.2s linear'
          }}
        >
          {arrLang.map((item, index) => (
            <Text
              key={item.value}
              fontWeight='500'
              fontSize='16px'
              w='28px'
              h='18px'
              data-value={item.value}
              textAlign='center'
              lineHeight='18px'
              cursor='pointer'
              display={index === 0 ? 'block' : 'none'}
              _groupHover={{ display: 'block' }}
              transition='display 0.2s ease-in-out'
              onClick={(event) => handlerChangeLang(event)}
            >
              {item.lang}
            </Text>
          ))}
          <Image src={Arrow}
            position='absolute'
            top='25.5px'
            right='25px'
            w='10px'
            h='5px'
            transition='all 0.4s linear'
            _groupHover={{ transform: 'rotateX(180deg)' }}
          />
        </Flex>
        <Link
          isExternal={true}
          // href='http://192.168.0.170:4173/'
          href='https://mid.myterior.kz/'
          h='54px'
          borderRadius='12px'
          p="18px 24px"
          bg='linear-gradient(90deg, #003B00, #000000, #003B00)'
          bgSize='300% 100%'
          animation={`${shine} 8s linear infinite`}
          _hover={{ backgroundColor: '#262626' }}
          _active={{ backgroundColor: '#333333' }}
          _focus={{ backgroundColor: '#B19477' }}
        >
          <Text
            color='#ffffff'
            fontSize='18px'
            lineHeight='18px'
            fontWeight='700'
          >
            Generate
          </Text>
        </Link>
        {user
          ?
          <>
            {user?.role !== 'member' && <BtnPremium />}
            <Menu
              isLazy
              offset={[90, 17]}
            >
              {({ onClose }) => (
                <>
                  <MenuButton
                    position='relative'
                    w='54px'
                    h='54px'
                    p='15px'
                    borderRadius='50%'
                    border='1px solid #0000001A'
                    bg='none'
                    className='group'
                    color='#B19477'
                    _hover={{ backgroundColor: '#B1947733' }}
                    _active={{ backgroundColor: '#B19477', color: '#ffffff' }}
                    _focus={{ border: '1px solid #B19477' }}
                  >
                    <Icon
                      as={Cart}
                    />
                    {cartArr?.length > 0 &&
                      <Text
                        position='absolute'
                        top='-3px'
                        right='-3px'
                        pt='6px'
                        fontSize='12px'
                        lineHeight='12px'
                        fontWeight='700'
                        color='#ffffff'
                        bg='#B19477'
                        borderRadius='50%'
                        w='24px'
                        h='24px'
                        zIndex='3'
                        textAlign='center'
                      >
                        {qty()}
                      </Text>
                    }
                  </MenuButton>
                  <MenuList
                    borderRadius='12px'
                    overflow='hidden'
                  >
                    <Flex
                      w='400px'

                    >
                      <MyCart onClose={onClose} />
                    </Flex>
                  </MenuList>
                </>
              )}
            </Menu>
            <Button
              w='54px'
              h='54px'
              borderRadius='50%'
              border='1px solid #0000001A'
              bg='none'
              color='#B19477'
              onClick={() => navigate('/profile')}
              _hover={{ backgroundColor: '#B1947733' }}
              _active={{ backgroundColor: '#B19477', color: '#ffffff' }}
              _focus={{ border: '1px solid #B19477' }}
            >
              {user?.avatar
                ?
                <Avatar
                  w='42px'
                  h='42px'
                  bg='none'
                  src={user?.avatar}
                />
                :
                <Icon
                  as={UserAvatar}
                />
              }
            </Button>
          </>
          :
          <>
            <BtnLogin />
            <BtnRegistration />
          </>
        }
      </Flex>
      <Flex
        position='absolute'
        w='100%'
        top='90px'
        left='0px'
        bg='#ece238'
        overflow='hidden'
        textAlign='center'
        ref={marqueeRef}
      >
        {Array.from({ length: Math.round(marqueeRef.current?.offsetWidth / 420) }).map((_, index) => (
          <Text
            key={`marquee-${index}`}
            color='#ffffff'
            bg='#000000'
            w='280px'
            fontSize='10px'
            lineHeight='14px'
            fontWeight='400'
            p='0 20px'
            whiteSpace='nowrap'
            animation={`${marquee.toString()} 10s linear infinite`}
            mr='280px'
            borderRadius='500px 0 500px 0'
          >
            {t('test')}
          </Text>
        ))}
      </Flex>
    </Flex>
  );
};