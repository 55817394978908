const CheckCircle = () => {
    return (
      <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Filled Icons/Check Circle" clipPath="url(#clip0_154_2584)">
      <g id="Check Circle Icon">
      <path id="Vector" d="M9.5 19.417C14.7469 19.417 19 15.1638 19 9.91699C19 4.67014 14.7469 0.416992 9.5 0.416992C4.25315 0.416992 0 4.67014 0 9.91699C0 15.1638 4.25315 19.417 9.5 19.417Z" fill="#B19477"/>
      <path id="Check" d="M5.20117 10.5313L7.65788 12.988L13.7997 6.84619" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      </g>
      <defs>
      <clipPath id="clip0_154_2584">
      <rect width="19" height="19" fill="white" transform="translate(0 0.416992)"/>
      </clipPath>
      </defs>
      </svg>
    )
}
export default CheckCircle;
