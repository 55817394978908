import { createSlice } from '@reduxjs/toolkit';

const watcherSlice = createSlice({
  name: 'watcher',
  initialState: {
    lensDotId: null,
    activeDots: [],
  },
  reducers: {
    setLensDotId: (state, action) => {
      state.lensDotId = action.payload;
      return state;
    },
    addActiveDots: (state, action) => {
      state.activeDots.push(action.payload);
      return state;
    },
    removeActiveDots: (state, action) => {
      state.activeDots = state.activeDots.filter(dot => dot !== action.payload);
      return state;
    }
  },
});

export const { setLensDotId } = watcherSlice.actions;

export default watcherSlice.reducer;
