import { 
    Flex,
    Text 
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import BtnMoreScenes from "../../../components/Buttons/BtnMoreScenes";
import ViewScenes from "../../../components/Scenes/ViewScenes";

export default function ScenesModule() {
  const { t } = useTranslation();
  const allScenes = useSelector((store) => store.scenes);
  const mainScenes = allScenes?.filter((scene, index) => index <= 7);
  
  return (
    <Flex
      w='1218px'
      flexDirection='column'
      alignItems='center'
      gap='45px'
      mb='90px'
    >
      <Text
       fontSize='46px'
       fontWeight='700'
       color='#000000'
       lineHeight='46px'
       letterSpacing='2px'
      >
        {t('main.scenes-module.header')}
      </Text>
      {allScenes?.length > 0 &&
        <ViewScenes allScenes={mainScenes} />
      }
      <BtnMoreScenes />
    </Flex>
  )
}