import { Button, Image, Text } from "@chakra-ui/react";
import Star1 from '../Images/Star1.svg';
import { useNavigate } from "react-router-dom";

export default function BtnGoPremium() {
  const navigate = useNavigate()
  return (
    <Button
      borderRadius='12px'
      p="18px 24px"
      h='62px'
      bg='#000000'
      onClick={() => navigate('/membership')}
      _hover={{ backgroundColor: '#262626' }}
      _active={{ backgroundColor: '#333333' }}
      _focus={{ backgroundColor: '#C24A4A' }}
    >
        <Image  
          src={Star1}
          />
          <Text
            ml='8px'
            color='#ffffff'
            fontSize='16px'
            lineHeight='18px'
            fontWeight='700'
          >
            Membership
          </Text>
    </Button>
  )
}