import { useDispatch } from "react-redux";

const useSSE = () => {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const dispatch = useDispatch();
  let eventSource;

  const openConnection = () => {
    if (!eventSource || eventSource.readyState === EventSource.CLOSED) {
      eventSource = new EventSource(`${serverUrl}/events`, { withCredentials: true });

      eventSource.addEventListener('google-serp-api', (event) => {
        console.log('google-serp-api');
        const data = JSON.parse(event.data);
        console.log('data: ', data);
        if (data.data) {
          dispatch({ type: 'items/addItems', payload: data });
        }
        dispatch({ type: 'watcher/removeActiveDots', payload: data.dotId });
      });

      eventSource.addEventListener('selected-data-catalog', (event) => {
        const data = JSON.parse(event.data);
        console.log(data);
        dispatch({ type: 'cart/addCart', payload: { ...data.data, quantity: data.quantity } });
      });

      eventSource.addEventListener('bunch-data-catalog', (event) => {
        console.log('bunch-data-catalog');
        const data = JSON.parse(event.data);
        console.log(data);
        if (data.data) {
          dispatch({ type: 'items/addItems', payload: data });
        }
        dispatch({ type: 'watcher/removeActiveDots', payload: data.dotId });
      });

      eventSource.addEventListener('new-seek-item', (event) => {
        console.log('new-seek-item');
        const data = JSON.parse(event.data);
        dispatch({ type: 'seekScenes/addNewSeekScene', payload: data.data });
        dispatch({ type: 'watcher/addActiveDots', payload: data.data.id });
        dispatch({ type: 'watcher/setLensDotId', payload: data.data.id });
      });

      eventSource.addEventListener('ping', (event) => {
        console.log('pong!');
      });

      eventSource.onerror = function (event) {
        if (event.target.readyState === EventSource.CLOSED) {
          console.log("SSE connection was closed, trying to reconnect...");
          eventSource = new EventSource(`${serverUrl}/events`, { withCredentials: true });
        } else if (event.target.readyState === EventSource.CONNECTING) {
          console.log("SSE connection is being reestablished...");
        }
      };
    }
  };

  const closeConnection = () => {
    if (eventSource && eventSource.readyState !== EventSource.CLOSED) {
      eventSource.close();
    }
  };

  return { openConnection, closeConnection }
};

export { useSSE };