import React, { useState } from 'react';
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import ViewIcon from "../Images/Profile/viewIcon";
import ViewIconOff from "../Images/Profile/viewIconOff";
import { useAuth } from '../../services/hooks/authService';

export default function SignIn({ setToggle, onClose }) {
  const { handlerAuthLogin } = useAuth();
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({});
  const [checkName, setCheckName] = useState(false);
  const [checkPassword, setCheckPassword] = useState(false);
  const [errorAuth, setErrorAuth] = useState(false);

  const handlerFormData = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handlerSubmit = async () => {
    if (!formData.username) {
      setCheckName(true);
      return;
    }

    if (!formData.password) {
      setCheckPassword(true);
      return;
    }

    const response = await handlerAuthLogin(formData);
    if (!response.error) {
      onClose();
    } else {
      setErrorAuth(true);
      return
    };
  };

  return (
    <Flex
      alignItems="center"
      flexDirection='column'
      height="100%"
      width="100%"
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          handlerSubmit();
        }
      }}
    >
      <Text
        fontSize='34px'
        fontWeight='500'
        alignSelf='flex-start'
        mb='40px'
      >
        {t('authentication.header-login')}
      </Text>
      <Flex
        flexDirection='column'
        w='100%'
        gap='26px'
        mb='24px'
      >
        <Popover
          returnFocusOnClose={false}
          isOpen={checkName}
          placement='top'
          closeOnBlur={true}
          onClose={() => setCheckName(false)}
        >
          <FormControl
            w='100%'
          >
            <FormLabel
              position='absolute'
              top='-14px'
              left='10px'
              zIndex='1'
              bg='white'
              p='0 10px'
              fontSize='14px'
              fontWeight='400'
              lineHeight='18px'
              color='#9b9b9b'
            >
              {t('authentication.name')}
            </FormLabel>
            <PopoverTrigger>
              <Input
                onChange={handlerFormData}
                type='text'
                name='username'
                placeholder={t('authentication.name')}
                h='48px'
                borderRadius='4px'
              />
            </PopoverTrigger>
          </FormControl>
          <PopoverContent
            bg='#C24A4A'
            borderColor='#C24A4A'
          >
            <PopoverArrow
              bg='#C24A4A'
            />
            <PopoverBody>
              <Text
                fontSize='14px'
                fontWeight='400'
                lineHeight='18px'
                color='#ffffff'
              >
                {t('authentication.error-name')}
              </Text>
            </PopoverBody>
          </PopoverContent>
        </Popover>
        <Popover
          returnFocusOnClose={false}
          isOpen={checkPassword}
          placement='top'
          closeOnBlur={true}
          onClose={() => setCheckPassword(false)}
        >
          <FormControl
            w='100%'
          >
            <FormLabel
              position='absolute'
              top='-14px'
              left='10px'
              zIndex='1'
              bg='white'
              p='0 10px'
              fontSize='14px'
              fontWeight='400'
              lineHeight='18px'
              color='#9b9b9b'
            >
              {t('authentication.password')}
            </FormLabel>
            <PopoverTrigger>
              <InputGroup>
                <Input
                  onChange={handlerFormData}
                  type={showPassword ? 'text' : 'password'}
                  name='password'
                  placeholder={t('authentication.password')}
                  h='48px'
                  borderRadius='4px'
                />
                <InputRightElement
                  h='full'
                  w='48px'
                >
                  <Button
                    variant='ghost'
                    onClick={() => setShowPassword((prev) => !prev)}
                    p='14px'
                    w='full'
                    h='full'
                    color='#B19477'
                    _focus={{ borderColor: '#B19477' }}
                    _hover={{ backgroundColor: '#B1947733' }}
                    _active={{ backgroundColor: '#B1947740' }}
                  >
                    {showPassword ? <Icon as={ViewIconOff} /> : <Icon as={ViewIcon} />}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </PopoverTrigger>
          </FormControl>
          <PopoverContent
            bg='#C24A4A'
            borderColor='#C24A4A'
          >
            <PopoverArrow
              bg='#C24A4A'
            />
            <PopoverBody>
              <Text
                fontSize='14px'
                fontWeight='400'
                lineHeight='18px'
                color='#ffffff'
              >
                {t('authentication.error-password')}
              </Text>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Flex>
      <Popover
        returnFocusOnClose={false}
        isOpen={errorAuth}
        placement='bottom'
        closeOnBlur={true}
        onClose={() => setErrorAuth(false)}
      >
        <PopoverTrigger>
          <Button
            minW='100%'
            h='54px'
            color='#ffffff'
            fontSize='16px'
            fontWeight='700'
            borderRadius='12px'
            p="18px 24px"
            mb='20px'
            bg='#000000'
            onClick={handlerSubmit}
            _hover={{ backgroundColor: '#262626' }}
            _active={{ backgroundColor: '#333333' }}
            _focus={{ backgroundColor: '#B19477' }}
          >
            {t('authentication.button-login')}
          </Button>
        </PopoverTrigger>
        <PopoverContent
          bg='#C24A4A'
          borderColor='#C24A4A'
        >
          <PopoverArrow
            bg='#C24A4A'
          />
          <PopoverBody>
            <Text
              fontSize='14px'
              fontWeight='400'
              lineHeight='18px'
              color='#ffffff'
            >
              {t('authentication.error-auth')}
            </Text>
          </PopoverBody>
        </PopoverContent>
      </Popover>
      <Flex
        gap='6px'
        alignItems='center'
      >
        <Text
          fontSize='12px'
          fontWeight='400'
          color='#828282'
        >
          {t('authentication.hint-reg')}
        </Text>
        <Button
          bg='none'
          fontSize='12px'
          fontWeight='500'
          p='0 2px'
          color='#B19477'
          w='fit-content'
          h='fit-content'
          onClick={() => setToggle(false)}
          _hover={{ backgroundColor: '#f8f5f2' }}
          _active={{}}
          _focus={{}}
        >
          {t('authentication.button-reg')}
        </Button>
      </Flex>
    </Flex>
  );
};