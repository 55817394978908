const Cart = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="vuesax/linear/bag">
    <g id="vuesax/linear/bag_2">
    <g id="bag">
    <path id="Vector" d="M8.81043 2L5.19043 5.63"  strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path id="Vector_2" d="M15.1904 2L18.8104 5.63"  strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path id="Vector_3" d="M2 7.84961C2 5.99961 2.99 5.84961 4.22 5.84961H19.78C21.01 5.84961 22 5.99961 22 7.84961C22 9.99961 21.01 9.84961 19.78 9.84961H4.22C2.99 9.84961 2 9.99961 2 7.84961Z"  strokeWidth="1.5"/>
    <path id="Vector_4" d="M9.75977 14V17.55"  strokeWidth="1.5" strokeLinecap="round"/>
    <path id="Vector_5" d="M14.3604 14V17.55"  strokeWidth="1.5" strokeLinecap="round"/>
    <path id="Vector_6" d="M3.5 10L4.91 18.64C5.23 20.58 6 22 8.86 22H14.89C18 22 18.46 20.64 18.82 18.76L20.5 10"  strokeWidth="1.5" strokeLinecap="round"/>
    </g>
    </g>
    </g>
    </svg>
  )
}


export default Cart;
