import { 
    List, 
    ListIcon, 
    ListItem, 
    Text 
} from '@chakra-ui/react';
import CheckCircle from '../../../components/Images/CheckCircle.js';
import { useTranslation } from 'react-i18next';

export default function FeaturesList() {
  const { t } = useTranslation(); 
  return (
    <List 
      spacing='23px'
    >
      <ListItem
        display='flex'
        gap='14px'
      >
        <ListIcon 
          as={CheckCircle} 
        />
        <Text
          fontSize='18px'
          lineHeight='18px'
          fontWeight='400'
          color='#000000'
        >
          {t('main.last-module.list1')}
        </Text> 
      </ListItem>
      <ListItem
        display='flex'
        gap='14px'
      >
        <ListIcon 
          as={CheckCircle} 
        />
        <Text
          fontSize='18px'
          lineHeight='18px'
          fontWeight='400'
          color='#000000'
        >
          {t('main.last-module.list2')}
        </Text>
      </ListItem>
      <ListItem
        display='flex'
        gap='14px'
      >
        <ListIcon 
          as={CheckCircle} 
        />
        <Text
          fontSize='18px'
          lineHeight='18px'
          fontWeight='400'
          color='#000000'
        >
          {t('main.last-module.list3')}
        </Text>
      </ListItem>
      <ListItem
        display='flex'
        gap='14px'
      >
        <ListIcon 
          as={CheckCircle} 
        />
        <Text
          fontSize='18px'
          lineHeight='18px'
          fontWeight='400'
          color='#000000'
        >
          {t('main.last-module.list4')}
        </Text>
      </ListItem>
    </List>    
  )
}