import React from "react";
import { Flex } from "@chakra-ui/react";
import ScanModule from "./ScanModule/ScanModule";
import HIWModule from "./HIWModule/HIWModule";
import ScenesModule from "./ScenesModule/ScenesModule";
import LastModule from "./LastModule/LastModule";
import KeyFeaturesModule from "./KeyFeatures/KeyFeatures";
import AboutUsModule from "./AboutUs/AboutUs";
import { useSelector } from "react-redux";
// import SubscribeModule from "./SubscribeModule/SubscribeModule";
// import MidModule from "./MidModule/MidModule";
// import FeaturesModule from "./FeaturesModule/FeaturesModule";

export default function Main() {
  const user = useSelector(store => store.user);

  return (
    <Flex
      flexDirection='column'
      alignItems='center'
      p='43px 111px'
    >
      <ScanModule />
      <HIWModule />
      <ScenesModule />
      <KeyFeaturesModule />
      <AboutUsModule />
      {user?.role !== 'member' && <LastModule />}
      {/* Блок каталога */}
      {/* <SubscribeModule /> */}
      {/* <FeaturesModule /> */}
      {/* <MidModule /> */}
      {/* Блок о нас */}
    </Flex>
  )
}