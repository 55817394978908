import React from "react";
import { Flex, Text } from "@chakra-ui/react";
// import { useTranslation } from "react-i18next";
// import CatalogCard from "../../components/CatalogCard"
// import SearchBar from "./SearchBar";
// import Pagination from "./Pagination";

export default function Catalog() {
  // const imageUrl = "https://woodc.pk/wp-content/uploads/2023/08/ajuhdfd.webp";
  // const title = "Modern sofa";
  // const description = "Beautiful Carved Shape arm sofa with Classy Work"
  // const price = 299;

  // const { t } = useTranslation();

  return (
    <Flex
      flexDirection='column'
      alignItems='center'
      minH='calc(100vh - 90px - 98px)'
      p='43px 111px'
    >
      <Text
        fontSize='46px'
        fontWeight='700'
        color='#000000'
        lineHeight='46px'
        letterSpacing='2px'
        mb='45px'
      >
        {/* {t("catalog.header")} */}
        Cooming soon
      </Text>
      {/* <SearchBar />
      <Flex
        flexWrap='wrap'
        gap='30px'
        w='1218px'
        mb='57px'
      >
        <CatalogCard imageUrl={imageUrl} title={title} description={description} price={price} />
        <CatalogCard imageUrl={imageUrl} title={title} description={description} price={price} />
        <CatalogCard imageUrl={imageUrl} title={title} description={description} price={price} />
        <CatalogCard imageUrl={imageUrl} title={title} description={description} price={price} />
        <CatalogCard imageUrl={imageUrl} title={title} description={description} price={price} />
        <CatalogCard imageUrl={imageUrl} title={title} description={description} price={price} />
        <CatalogCard imageUrl={imageUrl} title={title} description={description} price={price} />
        <CatalogCard imageUrl={imageUrl} title={title} description={description} price={price} />
      </Flex>
      <Pagination /> */}
    </Flex>
  );
};