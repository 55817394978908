import { Button, Flex, Image, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import ExitSvg from '../Images/Exit.svg';
import { useCarts } from "../../services/hooks/cartService";

export default function ItemsList({ arr }) {
  const { t } = useTranslation();
  const { removeFromCart } = useCarts();

  return (
    arr.map((item) => (
      <Flex
        key={`cartItem${item.id}`}
        justifyContent='space-between'
        alignItems='center'
      >
        <Image
          src={item.image_url}
          w='100%'
          maxW='94px'
          maxH='94px'
          borderRadius='12px'
        />
        <Flex
          flexDirection='column'
          gap='4px'
          justifyContent='center'
        >
          <Text
            fontSize='18px'
            fontWeight='500'
            lineHeight='22px'
            color='#000000'
          >
            {item.name}
          </Text>
          <Text
            fontSize='16px'
            lineHeight='18px'
            fontWeight='500'
            color='#9b9b9b'
          >
            {item.title}
          </Text>
        </Flex>
        <Flex
          flexDirection='column'
          gap='4px'
          justifyContent='center'
        >
          <Text
            fontSize='18px'
            fontWeight='500'
            lineHeight='22px'
            color='#000000'
          >
            ${item.price}
          </Text>
          <Text
            fontSize='16px'
            lineHeight='18px'
            fontWeight='500'
            color='#9b9b9b'
          >
            {t('my-cart.qty')}&nbsp;{item.quantity}
          </Text>
        </Flex>
        <Button
          background='none'
          width='20px'
          minW='20px'
          height='20px'
          className='group'
          transition='all 0.2s ease-in-out'
          p='0'
          data-itemid={item.id}
          _hover={{ background: 'none', transform: 'scale(1.1)' }}
          _active={{ transform: 'scale(0.9)' }}
          onClick={(event) => removeFromCart(Number(event.currentTarget.dataset.itemid))}
        >
          <Image
            w='20px'
            maxW='20px'
            p='0'
            src={ExitSvg}
          />
        </Button>
      </Flex>
    ))
  )
}