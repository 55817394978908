import React from "react";
import {
  Button,
  Flex,
  Text
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ItemsList from "./ListItems";

export default function MyCart({ onClose }) {
  const { t } = useTranslation();
  const cartArr = useSelector(store => store.cart);
  const navigate = useNavigate();
  const taxes = 0.1;

  const totalSum = (arr) => {
    let sum = 0;
    for (let item of arr) {
      sum += Number(item.price) * Number(item.quantity);
    }
    return sum
  }

  return (
    <Flex
      flexDirection='column'
      alignItems='center'
      w='100%'
      minH='200px'
      maxH='70vh'
      overflowY='auto'
      p='30px'
      bg='#ffffff'
    >
      {cartArr?.length > 0
        ?
        <Flex
          flexDirection='column'
          w='100%'
        >
          <Flex
            flexDirection='column'
            borderBottom='1px solid #e9e9e9'
            mb='24px'
            pb='36px'
            gap='36px'
          >
            <ItemsList arr={cartArr} />
          </Flex>
          <Flex
            justifyContent='space-between'
            mb='36px'
          >
            <Flex
              flexDirection='column'
              gap='4px'
            >
              <Text
                fontSize='18px'
                fontWeight='500'
                lineHeight='22px'
                color='#000000'
              >
                {t('my-cart.total')}
              </Text>
              <Text
                fontSize='16px'
                lineHeight='18px'
                fontWeight='500'
                color='#9b9b9b'
              >
                {t('my-cart.taxes')}&nbsp;${(totalSum(cartArr) * taxes).toFixed(2)}
              </Text>
            </Flex>
            <Text
              fontSize='36px'
              lineHeight='36px'
              fontWeight='500'
              color='#000000'
            >
              ${totalSum(cartArr).toFixed(2)}
            </Text>
          </Flex>
          <Button
            w='100%'
            maxW='300px'
            h='60px'
            bg='#000000'
            color='#ffffff'
            fontSize='16px'
            fontWeight='700'
            lineHeight='22px'
            alignSelf='center'
            borderRadius='12px'
            _hover={{ bg: '#000000' }}
            _active={{ bg: '#000000' }}
            onClick={() => { onClose(); navigate('/checkout') }}
          >
            {t('my-cart.button-checkout')}
          </Button>
        </Flex>
        :
        <Text
          fontSize='18px'
          fontWeight='500'
          lineHeight='22px'
          color='#000000'
        >
          {t('my-cart.empty')}
        </Text>
      }
    </Flex>
  );
}