import { useDispatch, useSelector } from "react-redux";

const useOrder = () => {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const userCart = useSelector(store => store.cart);
  const user = useSelector(store => store.user);
  const orders = useSelector(store => store.orders);
  const dispatch = useDispatch();

  const getOrders = async () => {
    try {
      if (orders.length === 0) {
        const response = await fetch(`${serverUrl}/order/get-orders?id=${user.id}`);
        if (response.status === 200) {
          const orders = await response.json();
          dispatch({ type: 'orders/setOrder', payload: orders });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const newOrder = async (data) => {
    const { userFormData, totalSum, shipping } = data;
    try {
      const response = await fetch(`${serverUrl}/order/new-order?id=${user.id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userFormData, totalSum, userCart, shipping }),
        credentials: "include",
      });
      if (response.status === 200) {
        const newOrder = await response.json();
        dispatch({ type: 'orders/addOrder', payload: newOrder });
        dispatch({ type: 'cart/clearCart' });
        return true
      } else {
        console.log('error: ', response.message);
        return false
      }
    } catch (error) {
      console.error(error);
      return false
    };
  };

  return { newOrder, getOrders };
};

export { useOrder };

