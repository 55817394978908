import {
    Flex,
    Image,
    Text,
    Link,
    Skeleton,
    Tooltip,
  } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import alibabaLogo from '../../components/Images/alibabaLogo.png';
// import googleLogo from '../components/Images/googleLogo.png';
import FlyingButton from 'react-flying-item';
import { useCarts } from '../../services/hooks/cartService';

const SceneItem = ({ item }) => {
  const { t } = useTranslation();
  const user = useSelector(store => store.user);
  const { addToCart } = useCarts();
  
  return (
    <Flex
      w='282px'
      flexDirection='column'
      borderRadius='12px'
      alignItems='center'
      background='#ffffff'
      overflow='hidden'
      pb='18px'
      mb='12px'
      gap='14px'
    >
      <Image
        width='100%'
        maxH='500px'
        src={item.image_url}
        alt={`Picture`}
        borderBottom='1px solid #B19477'
      />
      <Flex
        width='230px'
        h='37px'
        gap='10px'
        alignItems='center'
        justifyContent='space-between'
      >
        <Text
          fontSize='14px'
          lineHeight='20px'
          fontWeight='500'
          color='#000000'
        >
          {t('scan-scene.items-module.link')}
        </Text>
        {user?.role === 'member' ?
          <Link
            isExternal={true}
            href={item.url}
            fontSize='14px'
            lineHeight='20px'
            fontWeight='500'
            color='#000000'
          >
            {t('scan-scene.items-module.go-to-product')}
          </Link>
          :
          <Tooltip
            label='Only for members'
            bg='#b19477'
            color='#ffffff'
            placement='top-end'
            borderRadius='8px'
          >
            <Flex>
              <Skeleton
                borderRadius='8px'
                h='25px'
                startColor='#f2efec'
                endColor='#d7c2ac'
              >
                {t('scan-scene.items-module.only-for-members')}
              </Skeleton>
            </Flex>
          </Tooltip>
        }
        <Image
          h='100%'
          src={item?.properties?.source_icon ? item.properties.source_icon : alibabaLogo}
          alt={`alibaba`}
        />
      </Flex>
      <Flex
        width='230px'
        justifyContent='space-between'
      >
          <FlyingButton
            src={item.image_url }
            animationDuration={1}
            targetTop={'45px'}
            targetLeft={'calc(100vw - 210px)'}
            flyingItemStyling={{
              zIndex: 100,
              borderRadius: '4rem',
              width: '8rem'
            }}
            customAnimation={
              `50% {
              transform: scale(0.5);
              opacity: 0.8;
            }
            100% {
              transform: scale(0.1);
              opacity: 0.2;
            }`
            }
          >
            <Flex
              w='92px'
              h='37px'
              background='#000000'
              color='#ffffff'
              borderRadius='12px'
              onClick={() => addToCart(item)}
              justifyContent='center'
              alignItems='center'
              _hover={{ backgroundColor: '#262626' }}
              _active={{ backgroundColor: '#333333' }}
              _focus={{ backgroundColor: '#B19477' }}
            >
              <Text
                fontSize='14px'
                lineHeight='18px'
                fontWeight='700'
                color='#ffffff'
              >
                {t('scan-scene.items-module.button')}
              </Text>
            </Flex>
          </FlyingButton>
        <Text
          fontSize='18px'
          lineHeight='20px'
          fontWeight='500'
          color='#000000'
          p='8px 0'
          >
          ${item.price}
        </Text>
      </Flex>
    </Flex>
  );
};

export default SceneItem;
