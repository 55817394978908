import { 
  Box,
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from "@chakra-ui/react";
import interact from 'interactjs';
import { useTranslation } from "react-i18next";
import { useScenes } from "../../services/hooks/sceneService";
import { useSelector } from "react-redux";
import { useState } from "react";

export default function LensSearch({ frameRef, cropImg, sceneId, coeficient, image_dimension, onOpen }) {
  const { t } = useTranslation();
  const { postDataToSeekLensItems } = useScenes();
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector(store => store.user);

  function dragMoveListener(event) {
    var target = event.target
    // keep the dragged position in the data-x/data-y attributes
    var x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx
    var y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy
  
    // translate the element
    target.style.transform = 'translate(' + x + 'px, ' + y + 'px)'
  
    // update the posiion attributes
    target.setAttribute('data-x', x)
    target.setAttribute('data-y', y)
  }
  
  interact('.resize-drag')
    .resizable({
      // resize from all edges and corners
      edges: { left: true, right: true, bottom: true, top: true },

      listeners: {
        move(event) {
          var target = event.target
          var x = (parseFloat(target.getAttribute('data-x')) || 0)
          var y = (parseFloat(target.getAttribute('data-y')) || 0)

          // update the element's style
          target.style.width = event.rect.width + 'px'
          target.style.height = event.rect.height + 'px'

          // translate when resizing from top or left edges
          x += event.deltaRect.left
          y += event.deltaRect.top

          target.style.transform = 'translate(' + x + 'px,' + y + 'px)'

          target.setAttribute('data-x', x)
          target.setAttribute('data-y', y)
          // target.textContent = Math.round(event.rect.width) + '\u00D7' + Math.round(event.rect.height)
        }
      },
      modifiers: [
        // keep the edges inside the parent
        interact.modifiers.restrictEdges({
          outer: 'parent'
        }),

        // minimum size
        interact.modifiers.restrictSize({
          min: { width: 50, height: 50 }
        })
      ],

      inertia: true
    })
    .draggable({
      listeners: { move: dragMoveListener },
      inertia: true,
      modifiers: [
        interact.modifiers.restrictRect({
          restriction: 'parent',
          endOnly: true
        })
      ]
    })
  
  const handlePostCropImg = () => {
    if (user) {
      if (isLoading) return;
      const { dataset, offsetHeight, offsetWidth } = frameRef.current;
      const imgForPost = cropImg(offsetWidth, offsetHeight, dataset.x, dataset.y);
  
      const sendData = {
        sceneId: sceneId,
        bbox: [Math.round(dataset.x / coeficient), Math.round(dataset.y / coeficient), Math.round((Number(dataset.x) + Number(offsetWidth)) / coeficient), Math.round((Number(dataset.y) + Number(offsetHeight)) / coeficient)],
        cropped_img: imgForPost.slice(23),
        bbox_center: [Math.round((Number(dataset.x) + (Number(offsetWidth) / 2)) / coeficient), Math.round((Number(dataset.y) + (Number(offsetHeight) / 2)) / coeficient)],
        image_dimension: image_dimension
      }
      
      postDataToSeekLensItems(sendData);
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    } else {
      onOpen()
    }
  }

  return (
    <Popover>
      <PopoverTrigger>
        <Box
          className="resize-drag"
          zIndex={2}
          ref={frameRef}
          boxShadow={'0 0 0 9999px rgba(0, 0, 0, 0.3)'}
          style={{
            position: 'absolute',
            width: '120px',
            height: '120px',
            borderRadius: '20px',
            padding: '20px',
            borderLeft: '4px solid white',
            borderRight: '4px solid white',
            borderTop: '1px solid white',
            borderBottom: '1px solid white',
            touchAction: 'none',
          }}
        >
        </Box>
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          width={'fit-content'}
          bg={'none'}
          border={'none'}
        >
          <Button
            width={'60px'}
            background='#B19477'
            color='#ffffff'
            borderRadius='12px'
            _hover={{ backgroundColor: '#B19477' }}
            _active={{ backgroundColor: '#B19477' }}
            _focus={{ border: '1px solid #B19477' }}
            onClick={handlePostCropImg}
          >
            {t('scan-scene.find-button')}
          </Button>
        </PopoverContent>
      </Portal>
    </Popover>
  )
};