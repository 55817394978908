import React from "react";
import { Flex, Text } from "@chakra-ui/react";

export default function Page404() {

  return (
    <Flex
      flexDirection='column'
      alignItems='center'
      minH='calc(100vh - 90px - 98px)'
      p='43px 111px'
    >
      <Text
        fontSize='46px'
        fontWeight='700'
        color='#000000'
        lineHeight='46px'
        letterSpacing='2px'
        mb='45px'
      >
        Page not found
      </Text>
    </Flex>
  );
}