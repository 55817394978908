import './App.css';
import React, {
  useEffect,
  useLayoutEffect, 
  useRef 
} from 'react';
import { 
  useSelector 
} from 'react-redux';
import {
  Route, 
  Routes 
} from 'react-router-dom';
import Main from './pages/Main/Main';
import Scenes from './pages/Scenes/Scenes';
import Catalog from './pages/Catalog/Catalog';
import Profile from './pages/Profile/Profile';
import Page404 from './pages/Page404/Page404';
import NavBar from './components/NavBar/NavBar';
import Footer from './components/Footer/Footer';
import ScanScene from './pages/ScanScene/ScanScene';
import Subscription from './pages/Subscription/Subscription';
import CheckoutPage from './pages/Checkout/Checkout';
import { useSSE } from './services/hooks/sseService';
import { useAuth } from './services/hooks/authService';
import { useScenes } from './services/hooks/sceneService';
import ModalComponent from './components/ModalComponent';
import FeedbackModule from './components/Feedback/FeedbackModule';
import { useDisclosure } from '@chakra-ui/react';
import BtnFeedback from './components/Buttons/BtnFeedback';

export default function App() {
  const isRun = useRef(false);
  const isFBRun = useRef(false);
  const { checkAuth } = useAuth();
  const { openConnection, closeConnection } = useSSE();
  const { getScenesAndSeekScenes } = useScenes();
  const user = useSelector((store) => store.user);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    openConnection();

    return () => {
      closeConnection();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user && !isFBRun.current) {
      console.log('isFBRun.current: ', isFBRun.current);
      isFBRun.current = true;
      setTimeout(() => {
        onOpen();
      }, 20000);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useLayoutEffect(() => {
    if (window.innerWidth < 1440) {
      const intlScl = (window.outerWidth/1440).toFixed(2)
      const viewport = document.querySelector("meta[name=viewport]");
      viewport.setAttribute('content', `width=device-width, initial-scale=${intlScl}`);
    }
  }, []);

  const getScenes = async () => {
    const res = await getScenesAndSeekScenes();
    if (res) {
      console.log('res: ', res);
      checkAuth(res)
    }
  };

  useLayoutEffect(() => {
    if (!isRun.current) {
      isRun.current = true;
      getScenes();
      // getScenesAndSeekScenes();
      // checkAuth();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <NavBar />
      <Routes>
        <Route index element={<Main />} />
        <Route path="/catalog" element={<Catalog />} />
        <Route path="/scenes" element={<Scenes />} />
        <Route path="/scenes/show/:sceneId" element={<ScanScene />} />
        {user && (
          <>
            <Route path="/membership" element={<Subscription />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/checkout" element={<CheckoutPage />} />
          </>
        )}
        <Route path="*" element={<Page404 />} />
      </Routes>
      {user &&
        <>
          <BtnFeedback onOpen={onOpen} />
          <ModalComponent
            isOpen={isOpen}
            onClose={onClose}
            children={<FeedbackModule onClose={onClose} />}
          />
        </>
      }
      <Footer />
    </>
  );
};