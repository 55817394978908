import { Flex, Image, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import BtnExit from "../../../components/Buttons/BtnExit";
import BtnLogout from "../../../components/Buttons/BtnLogout";
import BtnCancel from "../../../components/Buttons/BtnCancel";
import LogoutIconBig from "../../../components/Images/Profile/logoutBig.svg";

export default function LogoutModal({ onClose }) { 
  const { t } = useTranslation();
  return (
    <Flex
      w='526px'
      h='409px'
      borderRadius='12px'
      bg='#f9f9f9'
      zIndex='3'
      position='relative'
      flexDirection='column'
      alignItems='center'
      p='48px 0 43px'
    >
      <BtnExit 
        handlerSwitch={onClose} 
        top={'30px'} 
        right={'37px'}
      />
      <Text
        fontSize='26px'
        lineHeight='23px'
        fontWeight='500'
        color='#000000'
        mb='53px'
      >
          {t('profile.logout-modal.header')}
      </Text>
      <Flex
        w='122px'
        h='122px'
        justifyContent='center'
        alignItems='center'
        bg='#ffffff'
        borderRadius='50%'
        color='#B19477'
        mb='55px'
      >
        <Image 
          src={LogoutIconBig}
        />
      </Flex>
      <Flex
        gap='12px'
      >
       <BtnLogout />
       <BtnCancel onClose={onClose}/>
      </Flex>
    </Flex>
  )
}