import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Flex, Image, Text } from "@chakra-ui/react";
import BtnExit from "../../../components/Buttons/BtnExit";
import { useTranslation } from "react-i18next";

export default function OrderDetails({ allOrders, onClose, orderId }) {
  const { t } = useTranslation();

  const orderById = () => {
    const order = allOrders.find((order) => order.id === orderId);
    const {orderInfo, items, deliveryAddress} = order;

    return (
      <Flex
        flexDirection='column'
        alignItems='start'
        gap='10px'
        w='100%'
      >
        <Text
          fontSize='18px'
          fontWeight='500'
          lineHeight='22px'
          color='#000000'
        >
          Name: {deliveryAddress.delivery_name} {deliveryAddress.delivery_last_name}
        </Text>
   
        <Text
          fontSize='18px'
          fontWeight='500'
          lineHeight='22px'
          color='#000000'
        >
          Email: {deliveryAddress.delivery_email}
        </Text>
        <Text
          fontSize='18px'
          fontWeight='500'
          lineHeight='22px'
          color='#000000'
        >
          Address: {deliveryAddress.delivery_address}
        </Text>
        <Text
          fontSize='18px'
          fontWeight='500'
          lineHeight='22px'
          color='#000000'
        >
          State: {deliveryAddress.delivery_state}
        </Text>
        <Text
          fontSize='18px'
          fontWeight='500'
          lineHeight='22px'
          color='#000000'
        >
          City: {deliveryAddress.delivery_city}
        </Text>
        <Text
          fontSize='18px'
          fontWeight='500'
          lineHeight='22px'
          color='#000000'
        >
          ZIP: {deliveryAddress.delivery_zip}
        </Text>
        <Text
          fontSize='18px'
          fontWeight='500'
          lineHeight='22px'
          color='#000000'
        >
          Phone: {deliveryAddress.delivery_phone}
        </Text>
       
        <Text
          fontSize='18px'
          fontWeight='500'
          lineHeight='22px'
          color='#000000'
        >
          Order Date: {new Date(deliveryAddress.createdAt).toLocaleDateString()}
        </Text>
        {/* <Text
          fontSize='18px'
          fontWeight='500'
          lineHeight='22px'
          color='#000000'
        >
          Delivery: ${order.delivery}
        </Text> */}
        <Text
          fontSize='18px'
          fontWeight='500'
          lineHeight='22px'
          color='#000000'
        >
          Order Total: ${orderInfo.total_sum}
        </Text>
        <Accordion
          allowMultiple
          w='100%'
        >
          <AccordionItem>
            <AccordionButton>
              <Text
                fontSize='18px'
                fontWeight='500'
                lineHeight='22px'
                color='#000000'
                flex='1'
              >
                Items
              </Text>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              {items.map((item, index) => (
                <Flex
                  key={`item${item.id}`}
                  alignItems='center'
                  gap='20px'
                  p='20px 0'
                  borderBottom={index + 1 !== items.length ? '1px solid #e9e9e9' : 'none'}
                >
                  <Image
                    src={item.image_url}
                    w='90px'
                    borderRadius='12px'
                  />
                  <Text
                    fontSize='18px'
                    fontWeight='500'
                    lineHeight='22px'
                    color='#000000'
                  >
                    Price: ${item.price}
                  </Text>
                  <Text
                    fontSize='18px'
                    fontWeight='500'
                    lineHeight='22px'
                    color='#000000'
                  >
                    Quantity: {item.quantity}
                  </Text>
                  <Text
                    fontSize='18px'
                    fontWeight='500'
                    lineHeight='22px'
                    color='#000000'
                  >
                    Sum: ${Number(item.quantity) * Number(item.price)}
                  </Text>
                </Flex>
              ))}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Flex>
    )
  }

  return (
    <Flex
      w='640px'
      maxH='80vh'
      overflowY='auto'
      borderRadius='12px'
      bg='#f9f9f9'
      zIndex='3'
      position='relative'
      flexDirection='column'
      alignItems='center'
      p='48px 40px 43px'
      gap='30px'
    >
      <BtnExit
        handlerSwitch={onClose}
        top={'30px'}
        right={'37px'}
      />
      <Text
        fontSize='26px'
        lineHeight='23px'
        fontWeight='500'
        color='#000000'
      >
        {t('profile.orders-section.button')}
      </Text>
      {orderById()}
    </Flex>
  )
}