import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Flex, Image, Text } from '@chakra-ui/react';

export default function Footer() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Flex
      h={'98px'}
      w={'100%'}
      justifyContent={'space-between'}
      alignItems={'center'}
      p={'0 111px'}
      zIndex={2}
    >
        <Image 
          src="/Logo.svg" 
          alt="MyTerior logo" 
          cursor='pointer'
          onClick={() => navigate('/')}
          />
        <Text
          opacity='0.3'
          fontSize='18px'
          fontWeight='400'
        >
            {t('footer.copyright')}
        </Text>
    </Flex>
  )
}