import {
  Button,
  Flex,
  FormControl, 
  FormLabel, 
  Image, 
  Input, 
  Radio, 
  RadioGroup, 
  Text, 
  useDisclosure
} from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ItemsList from "../../components/Cart/ListItems";
import DeliveryImg from "../../components/Images/Delivery.png"
import ModalComponent from "../../components/ModalComponent";
import SuccessOrder from "./SuccessOrder";
import { useOrder } from "../../services/hooks/orderService";

export default function CheckoutPage() {
  const { t } = useTranslation();
  const { newOrder } = useOrder();
  const cartArr = useSelector(store => store.cart);
  const user = useSelector(store => store.user);
  const [formData, setFormData] = useState();
  const [shipping, setShipping] = useState('30');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const taxes = 0.1;

  const handlerFormData = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const totalSum = (arr) => {
    let sum = 0;
    for (let item of arr) {
      sum += item.price * item.quantity;
    }
    return sum
  }

  const handlerSubmit = async () => {
    const data = {
      userFormData: formData,
      totalSum: Number((totalSum(cartArr) + Number(shipping)).toFixed(2)),
      shipping: shipping
    };
    console.log(data);
    const res = await newOrder(data);
    console.log('res: ', res);
    if (res) {
      onOpen();
    }
  }

  return (
    <Flex
      alignSelf='center'
      w='1440px'
      p='43px 111px'
      gap='30px'
      minH='calc(100vh - 90px - 98px)'
      flexDirection='column'
    >
      <Text
        fontSize='36px'
        fontWeight='700'
        lineHeight='36px'
        color='#000000'
      >
        {t('checkout.header')}
      </Text>
      {cartArr?.length > 0
        ?
        <Flex
          gap='30px'
          w="100%"
        >
          <Flex
            w='100%'
            h='fit-content'
            bg='#ffffff'
            borderRadius='12px'
            p='24px 40px 48px'
            flexDirection='column'
          >
            <Flex
              flexDirection='column'
              borderBottom='1px solid #e9e9e9'
              w='100%'
              pb='36px'
              gap='30px'
            >
              <Text
                fontSize='26px'
                fontWeight='500'
                lineHeight='28px'
                color='#000000'
              >
                {t('checkout.shipping-info.billing.header')}
              </Text>
              <Flex
                w='100%'
                gap='18px'
              >
                <FormControl
                  w='100%'
                >
                  <FormLabel
                    position='absolute'
                    top='-14px'
                    left='10px'
                    zIndex='1'
                    bg='white'
                    p='0 10px'
                    fontSize='14px'
                    fontWeight='400'
                    lineHeight='18px'
                    color='#9b9b9b'
                  >
                    {t('profile.account-section.name')}
                  </FormLabel>
                  <Input
                    onChange={handlerFormData}
                    type='text'
                    name='delivery_name'
                    placeholder={user.name}
                    h='48px'
                    borderRadius='4px'
                  />
                </FormControl>
                <FormControl
                  w='100%'
                >
                  <FormLabel
                    position='absolute'
                    top='-14px'
                    left='10px'
                    zIndex='1'
                    bg='white'
                    p='0 10px'
                    fontSize='14px'
                    fontWeight='400'
                    lineHeight='18px'
                    color='#9b9b9b'
                  >
                    {t('profile.account-section.surname')}
                  </FormLabel>
                  <Input
                    onChange={handlerFormData}
                    type='text'
                    name='delivery_last_name'
                    placeholder={user.surname}
                    h='48px'
                    borderRadius='4px'
                  />
                </FormControl>
              </Flex>
              <FormControl
                w='100%'
              >
                <FormLabel
                  position='absolute'
                  top='-14px'
                  left='10px'
                  zIndex='1'
                  bg='white'
                  p='0 10px'
                  fontSize='14px'
                  fontWeight='400'
                  lineHeight='18px'
                  color='#9b9b9b'
                >
                  {t('profile.account-section.email')}
                </FormLabel>
                <Input
                  onChange={handlerFormData}
                  type='email'
                  name='delivery_email'
                  placeholder={user.mail}
                  h='48px'
                  borderRadius='4px'
                />
              </FormControl>
              <FormControl
                w='100%'
              >
                <FormLabel
                  position='absolute'
                  top='-14px'
                  left='10px'
                  zIndex='1'
                  bg='white'
                  p='0 10px'
                  fontSize='14px'
                  fontWeight='400'
                  lineHeight='18px'
                  color='#9b9b9b'
                >
                  {t('profile.account-section.address')}
                </FormLabel>
                <Input
                  onChange={handlerFormData}
                  type='text'
                  name='delivery_address'
                  placeholder={user.address}
                  h='48px'
                  borderRadius='4px'
                />
              </FormControl>
              <Flex
                w='100%'
                gap='18px'
              >
                <FormControl
                  w='100%'
                >
                  <FormLabel
                    position='absolute'
                    top='-14px'
                    left='10px'
                    zIndex='1'
                    bg='white'
                    p='0 10px'
                    fontSize='14px'
                    fontWeight='400'
                    lineHeight='18px'
                    color='#9b9b9b'
                  >
                    {t('profile.account-section.state-province')}
                  </FormLabel>
                  <Input
                    onChange={handlerFormData}
                    type='text'
                    name='delivery_state'
                    placeholder='State'
                    h='48px'
                    borderRadius='4px'
                  />
                </FormControl>
                <FormControl
                  w='100%'
                >
                  <FormLabel
                    position='absolute'
                    top='-14px'
                    left='10px'
                    zIndex='1'
                    bg='white'
                    p='0 10px'
                    fontSize='14px'
                    fontWeight='400'
                    lineHeight='18px'
                    color='#9b9b9b'
                  >
                    {t('profile.account-section.city')}
                  </FormLabel>
                  <Input
                    onChange={handlerFormData}
                    type='text'
                    name='delivery_city'
                    placeholder='City'
                    h='48px'
                    borderRadius='4px'
                  />
                </FormControl>
              </Flex>
              <Flex
                w='100%'
                gap='18px'
              >
                <FormControl
                  w='100%'
                >
                  <FormLabel
                    position='absolute'
                    top='-14px'
                    left='10px'
                    zIndex='1'
                    bg='white'
                    p='0 10px'
                    fontSize='14px'
                    fontWeight='400'
                    lineHeight='18px'
                    color='#9b9b9b'
                  >
                    {t('profile.account-section.zip')}
                  </FormLabel>
                  <Input
                    onChange={handlerFormData}
                    type='number'
                    name='delivery_zip'
                    placeholder='Zip'
                    h='48px'
                    borderRadius='4px'
                  />
                </FormControl>
                <FormControl
                  w='100%'
                >
                  <FormLabel
                    position='absolute'
                    top='-14px'
                    left='10px'
                    zIndex='1'
                    bg='white'
                    p='0 10px'
                    fontSize='14px'
                    fontWeight='400'
                    lineHeight='18px'
                    color='#9b9b9b'
                  >
                    {t('profile.account-section.phone')}
                  </FormLabel>
                  <Input
                    onChange={handlerFormData}
                    type='tel'
                    name='delivery_phone'
                    placeholder={user.phone}
                    h='48px'
                    borderRadius='4px'
                  />
                </FormControl>
              </Flex>
            </Flex>
            <Flex
              flexDirection='column'
              w='100%'
              p='26px 0'
              gap='24px'
            >
              <Text
                fontSize='26px'
                fontWeight='500'
                lineHeight='28px'
                color='#000000'
              >
                {t('checkout.shipping-info.shipping.header')}
              </Text>
              <RadioGroup
                onChange={setShipping}
                defaultValue='30'
                value={shipping}
              >
                <Flex
                  flexDirection='column'
                  gap='15px'
                >
                  <Flex
                    h='74px'
                    border='1px solid #e2e8f0'
                    borderRadius='4px'
                    pl='19px'
                    transition='all 0.2s linear'
                    sx={shipping === '30' && { border: '1px solid #b19478', background: '#f2efec' }}
                  >
                    <Radio
                      value='30'
                      _checked={{ border: '5px solid #b19478' }}
                      sx={{ border: '1px solid #828282' }}
                    >
                      <Flex
                        w='479px'
                        h='100%'
                        p='15px'
                        justifyContent='space-between'
                        alignItems='center'
                      >
                        <Text
                          fontSize='16px'
                          fontWeight='500'
                          lineHeight='18px'
                          color='#000000'
                        >
                          $30
                        </Text>
                        <Text
                          fontSize='16px'
                          fontWeight='400'
                          lineHeight='18px'
                          color='#000000'
                        >
                          Express
                        </Text>
                        <Image src={DeliveryImg} />
                      </Flex>
                    </Radio>
                  </Flex>
                  <Flex
                    h='74px'
                    border='1px solid #e2e8f0'
                    borderRadius='4px'
                    pl='19px'
                    transition='all 0.2s linear'
                    sx={shipping === '20' && { border: '1px solid #b19478', background: '#f2efec' }}
                  >
                    <Radio
                      value='20'
                      _checked={{ border: '5px solid #b19478' }}
                      sx={{ border: '1px solid #828282' }}
                    >
                      <Flex
                        w='479px'
                        h='100%'
                        p='15px'
                        justifyContent='space-between'
                        alignItems='center'
                      >
                        <Text
                          fontSize='16px'
                          fontWeight='500'
                          lineHeight='18px'
                          color='#000000'
                        >
                          $20
                        </Text>
                        <Text
                          fontSize='16px'
                          fontWeight='400'
                          lineHeight='18px'
                          color='#000000'
                        >
                          Priority
                        </Text>
                        <Image src={DeliveryImg} />
                      </Flex>
                    </Radio>
                  </Flex>
                  <Flex
                    h='74px'
                    border='1px solid #e2e8f0'
                    borderRadius='4px'
                    pl='19px'
                    transition='all 0.2s linear'
                    sx={shipping === '10' && { border: '1px solid #b19478', background: '#f2efec' }}
                  >
                    <Radio
                      value='10'
                      _checked={{ border: '5px solid #b19478' }}
                      sx={{ border: '1px solid #828282' }}
                    >
                      <Flex
                        w='479px'
                        h='100%'
                        p='15px'
                        justifyContent='space-between'
                        alignItems='center'
                      >
                        <Text
                          fontSize='16px'
                          fontWeight='500'
                          lineHeight='18px'
                          color='#000000'
                        >
                          $10
                        </Text>
                        <Text
                          fontSize='16px'
                          fontWeight='400'
                          lineHeight='18px'
                          color='#000000'
                        >
                          Common
                        </Text>
                        <Image src={DeliveryImg} />
                      </Flex>
                    </Radio>
                  </Flex>
                </Flex>
              </RadioGroup>
            </Flex>
            <Button
              w='100%'
              maxW='300px'
              h='60px'
              bg='#000000'
              color='#ffffff'
              fontSize='16px'
              fontWeight='700'
              lineHeight='22px'
              alignSelf='center'
              borderRadius='12px'
              _hover={{ bg: '#000000' }}
              _active={{ bg: '#000000' }}
              onClick={handlerSubmit}
            >
              {`${t('checkout.button-pay')}$${(totalSum(cartArr) + Number(shipping)).toFixed(2)}`}
            </Button>
            {/* предупреждение о том что не работает доставка */}
            <Text
              alignSelf='center'
              textAlign='center'
              mt='20px'
              color='#9b9b9b'
            >
              {t('checkout-disclaimer')}
            </Text>
          </Flex>
          {/* правый раздел */}
          <Flex
            w='100%'
            h='fit-content'
            bg='#ffffff'
            borderRadius='12px'
            p='24px 40px 48px'
            flexDirection='column'
            gap='36px'
          >
            <Text
              fontSize='26px'
              fontWeight='500'
              lineHeight='28px'
              color='#000000'
            >
              {t('checkout.order-summary.header')}
            </Text>
            <Flex
              flexDirection='column'
              borderBottom='1px solid #e9e9e9'
              pb='36px'
              gap='36px'
            >
              <ItemsList arr={cartArr} />
            </Flex>
            <Flex
              flexDirection='column'
              borderBottom='1px solid #e9e9e9'
              gap='16px'
              pb='36px'
            >
              <Flex
                justifyContent='space-between'
              >
                <Text
                  fontSize='16px'
                  fontWeight='500'
                  lineHeight='18px'
                  color='#000000'
                >
                  {t('checkout.order-summary.subtotal')}
                </Text>
                <Text
                  fontSize='16px'
                  fontWeight='500'
                  lineHeight='18px'
                  color='#000000'
                >
                  ${totalSum(cartArr).toFixed(2)}
                </Text>
              </Flex>
              <Flex
                justifyContent='space-between'
              >
                <Text
                  fontSize='16px'
                  fontWeight='500'
                  lineHeight='18px'
                  color='#000000'
                >
                  {t('checkout.order-summary.shipping')}
                </Text>
                <Text
                  fontSize='16px'
                  fontWeight='500'
                  lineHeight='18px'
                  color='#000000'
                >
                  ${shipping}
                </Text>
              </Flex>
            </Flex>
            <Flex
              justifyContent='space-between'
            >
              <Flex
                flexDirection='column'
                gap='4px'
              >
                <Text
                  fontSize='18px'
                  fontWeight='500'
                  lineHeight='22px'
                  color='#000000'
                >
                  {t('my-cart.total')}
                </Text>
                <Text
                  fontSize='16px'
                  lineHeight='18px'
                  fontWeight='500'
                  color='#9b9b9b'
                >
                  {t('my-cart.taxes')}&nbsp;${(totalSum(cartArr) * taxes).toFixed(2)}
                </Text>
              </Flex>
              <Text
                fontSize='36px'
                lineHeight='36px'
                fontWeight='500'
                color='#000000'
              >
                ${(totalSum(cartArr) + Number(shipping)).toFixed(2)}
              </Text>
            </Flex>
          </Flex>
        </Flex>
        
        :
        <Text
          fontSize='18px'
          fontWeight='500'
          lineHeight='22px'
          color='#000000'
        >
          {t('my-cart.empty')}
        </Text>
      }
        <ModalComponent
          isOpen={isOpen}
          onClose={onClose}
          children={<SuccessOrder onClose={onClose} />}
        />
    </Flex>
  )
}