import { Flex, Image } from "@chakra-ui/react";
import SignIn from "./Signin";
import SignUp from "./Signup";
import LoginPic from "../Images/LoginPic.png"
import RegPic from "../Images/RegPic.png"
import RectWave from '../Images/Rectangle16.png';
import BtnExit from "../Buttons/BtnExit";
import { useState } from "react";

export default function AuthModule({ authSwitch, onClose }) {
  const [toggle, setToggle] = useState(authSwitch);
  

  return (
    <Flex
      w='880px'
      h='480px'
      borderRadius='12px'
      bg='#ffffff'
      overflow='hidden'
    >
      <Flex
        justifyContent='center'
        alignItems='center'
        w='50%'
        h='100%'
        backgroundSize='cover'
        backgroundImage={`linear-gradient(90deg, #FAE4CE40, #D2965340), url(${RectWave})`}
      >
        <Image
          src={toggle ? LoginPic : RegPic}
        />
      </Flex>
      <Flex
        w='50%'
        h='100%'
        p='44px 54px 0px'
      >
        {toggle
          ? <SignIn onClose={onClose} setToggle={setToggle} />
          : <SignUp onClose={onClose} setToggle={setToggle} />
        }
      </Flex>
      <BtnExit
        top={'24px'}
        right={'26px'}
        handlerSwitch={onClose}
      />
    </Flex>
  );
}

