import { Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import SideSection from "./Sections/SideSection";
import ScenesSection from "./Sections/ScenesSection";
import { useLocation } from "react-router-dom";
import PremiumSection from "./Sections/PremiumSection";
import AccountSection from "./Sections/AccountSection";
import OrdersSection from "./Sections/OrdersSection";
import { useOrder } from "../../services/hooks/orderService";
import { useEffect, useRef } from "react";

export default function Profile() {
  const { t } = useTranslation();
  const locationPath = useLocation();
  const { getOrders } = useOrder();
  const isRun = useRef(false);
  
  useEffect(() => {
    if (!isRun.current) {
      getOrders()
      isRun.current = true;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const switchSection = () => {
    switch (locationPath.hash) {
        case '#scenes':
          return <ScenesSection />
        case '#membership':
          return <PremiumSection />
        case '#orders':
          return <OrdersSection />
        default:
          return <AccountSection />
      }
  }

  return (
    <Flex
      justifyContent='center'
      p='43px 111px'
      gap='30px'
      minH='calc(100vh - 90px - 98px)'
    >
      <Flex
        flexDirection='column'
        w='282px'
      >
        <Text
          fontSize='28px'
          fontWeight='500'
          lineHeight='36px'
          color='#000000'
          mb='18px'
        >
           {t('profile.side-section.header')}
        </Text>
        <SideSection />
      </Flex>
      <Flex
        w='906px'
        flexDirection='column'
        gap='18px'
      >
        {switchSection()}
      </Flex>
    </Flex>
  )
};