import { Button, Flex, Icon, Text } from "@chakra-ui/react";

export default function BtnProfile({ handlerSwitchSection, iconPic, title, sectionId }) {
  return (
    <Button
      w='100%'
      h='56px'
      borderRadius='8px'
      p='16px 18px'
      gap='12px'
      bg='none'
      id={sectionId}
      color='#000000'
      className='group'
      justifyContent='flex-start'
      onClick={ handlerSwitchSection }
      _hover={{ backgroundColor: '#f8f5f2', color: '#B19477' }}
      _active={{ backgroundColor: '#f8f5f2', color: '#B19477' }}
      _focus={{ backgroundColor: '#f8f5f2', color: '#B19477' }}
    >
      <Flex
       w='24px'
       justifyContent='center'
       alignItems='center'
       bg='none'
       color='#b3b3b3'
       _groupHover={{ color: '#B19477' }}
       _groupActive={{ color: '#B19477' }}
       _groupFocus={{ color: '#B19477' }}
      >
        <Icon
          as={iconPic}
        />
      </Flex>
      <Text
        color='currentColor'
        fontSize='16px'
        fontWeight='400'
        lineHeight='24px'
      >
        {title}
      </Text>
    </Button>
  )
}