import {
  Box,
  Flex,
  Text,
  keyframes,
  Image as ChakraImage, 
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import ScanRect from '../../components/Images/ScanRect.svg';
import ScanDots from '../../components/Images/ScanDots.svg';

export default function ScanTutorial({ lensSwitch }) {
  const { t } = useTranslation();

  const loadingPulse = keyframes({
    '0%': {
      outline: '4px solid #fffff'
    },
    '50%': {
      outline: '4px solid #C5AB50'
    },
    '100%': {
      outline: '4px solid #ffffff'
    }
  });

  return (
    <Flex
      w='100%'
      bg='#eee6df'
      p='20px 24px'
      borderRadius='12px'
      gap='10px'
    >
      <Flex
        flexDirection='column'
        alignItems='center'
      >
        <Box
          m='2px 0 22px'
          width='16px'
          height='16px'
          background='#B19477'
          border='4px solid white'
          borderRadius='50%'
          boxShadow='0px 0px 10px 0px rgba(0, 0, 0, 0.1)'
        />
        <Box
          width='8px'
          height='8px'
          m='4px 0 26px'
          border='4px solid #B19477'
          borderRadius='50%'
          outline='4px solid #ffffff'
          animation={`${loadingPulse} 1s linear infinite`}
          boxShadow='0px 0px 10px 0px rgba(0, 0, 0, 0.1)'
        />
        <Box
          mb='18px'
          width='16px'
          height='16px'
          background='#748D63'
          border='4px solid white'
          borderRadius='50%'
          boxShadow='0px 0px 10px 0px rgba(0, 0, 0, 0.1)'
        />
        <Flex
          mb='18px'
          bg='white'
          borderRadius='50%'
          width='24px'
          minW='24px'
          height='24px'
          className='group'
          justifyContent='center'
          alignItems='center'
          boxShadow='0px 0px 10px 0px rgba(0, 0, 0, 0.1)'
        >
          <ChakraImage
            w={lensSwitch ? '12px' : '16px'}
            maxW={lensSwitch ? '12px' : '16px'}
            p='0'
            src={lensSwitch ? ScanRect : ScanDots}
          />
        </Flex>
        <Box
          mb='18px'
          width='24px'
          height='18px'
          border='5px solid white'
          borderLeft='3px solid white'
          borderRight='3px solid white'
          borderTop='1px solid white'
          borderBottom='1px solid white'
          borderRadius='8px'
          boxShadow='0px 0px 10px 0px rgba(0, 0, 0, 0.1)'
        />
        <Flex
          background='#B19477'
          color='#ffffff'
          fontSize='10px'
          borderRadius='6px'
          justifyContent='center'
          alignItems='center'
          p='2px 5px'
          boxShadow='0px 0px 10px 0px rgba(0, 0, 0, 0.1)'
        >
          {t('scan-scene.find-button')}
        </Flex>
      </Flex>
      <Flex
        gap='20px'
        flexDirection='column'
      >
        <Text
          color='#000000'
          fontSize='18px'
          lineHeight='18px'
          fontWeight='400'
        >
          {t('scan-scene.tutorial.dot-default')}
        </Text>
        <Text
          color='#000000'
          fontSize='18px'
          lineHeight='18px'
          fontWeight='400'
        >
          {t('scan-scene.tutorial.dot-search')}
        </Text>
        <Text
          color='#000000'
          fontSize='18px'
          lineHeight='18px'
          fontWeight='400'
        >
          {t('scan-scene.tutorial.dot-finish')}
        </Text>
        <Text
          color='#000000'
          fontSize='18px'
          lineHeight='18px'
          fontWeight='400'
        >
          {t('scan-scene.tutorial.lens-switcher')}
        </Text>
        <Text
          color='#000000'
          fontSize='18px'
          lineHeight='18px'
          fontWeight='400'
        >
          {t('scan-scene.tutorial.lens-selection')}
        </Text>
        <Text
          color='#000000'
          fontSize='18px'
          lineHeight='18px'
          fontWeight='400'
        >
          {t('scan-scene.tutorial.lens-button')}
        </Text>
      </Flex>
    </Flex>
    // <Flex
    //   w='100%'
    //   bg='#eee6df'
    //   p='30px 30px'
    //   borderRadius='12px'
    //   flexDirection='column'
    //   gap='20px'
    // >
    //   <Flex
    //     gap='20px'
    //   >
    //     <Box
    //       width='16px'
    //       height='16px'
    //       background='#B19477'
    //       border='4px solid white'
    //       borderRadius='50%'
    //       boxShadow='0px 0px 10px 0px rgba(0, 0, 0, 0.1)'
    //     />
    //     <Text
    //       color='#000000'
    //       fontSize='18px'
    //       lineHeight='18px'
    //       fontWeight='400'
    //     >
    //       {t('scan-scene.tutorial.dot-default')}
    //     </Text>
    //   </Flex>
    //   <Flex
    //     alignItems='center'
    //     gap='20px'
    //   >
    //     <Box
    //       width='8px'
    //       height='8px'
    //       m='0 4px'
    //       border='4px solid #B19477'
    //       borderRadius='50%'
    //       outline='4px solid #ffffff'
    //       animation={`${loadingPulse} 1s linear infinite`}
    //       boxShadow='0px 0px 10px 0px rgba(0, 0, 0, 0.1)'
    //     />
    //     <Text
    //       color='#000000'
    //       fontSize='18px'
    //       lineHeight='18px'
    //       fontWeight='400'
    //     >
    //       {t('scan-scene.tutorial.dot-search')}
    //     </Text>
    //   </Flex>
    //   <Flex
    //     gap='20px'
    //   >
    //     <Box
    //       width='16px'
    //       height='16px'
    //       background='#748D63'
    //       border='4px solid white'
    //       borderRadius='50%'
    //       boxShadow='0px 0px 10px 0px rgba(0, 0, 0, 0.1)'
    //     />
    //     <Text
    //       color='#000000'
    //       fontSize='18px'
    //       lineHeight='18px'
    //       fontWeight='400'
    //     >
    //       {t('scan-scene.tutorial.dot-finish')}
    //     </Text>
    //   </Flex>
    //   <Flex
    //     gap='20px'
    //     alignItems='center'
    //   >
    //     <Flex
    //       bg='white'
    //       borderRadius='50%'
    //       width='24px'
    //       minW='24px'
    //       height='24px'
    //       className='group'
    //       justifyContent='center'
    //       alignItems='center'
    //       boxShadow='0px 0px 10px 0px rgba(0, 0, 0, 0.1)'
    //     >
    //       <ChakraImage
    //         w={lensSwitch ? '12px' : '16px'}
    //         maxW={lensSwitch ? '12px' : '16px'}
    //         p='0'
    //         src={lensSwitch ? ScanRect : ScanDots}
    //       />
    //     </Flex>
    //     <Text
    //       color='#000000'
    //       fontSize='18px'
    //       lineHeight='18px'
    //       fontWeight='400'
    //     >
    //       {t('scan-scene.tutorial.lens-switcher')}
    //     </Text>
    //   </Flex>
    //   <Flex
    //     gap='20px'
    //     alignItems='center'
    //   >
    //     <Box
    //       width='24px'
    //       height='18px'
    //       border='5px solid white'
    //       borderLeft='3px solid white'
    //       borderRight='3px solid white'
    //       borderTop='1px solid white'
    //       borderBottom='1px solid white'
    //       borderRadius='8px'
    //       boxShadow='0px 0px 10px 0px rgba(0, 0, 0, 0.1)'
    //     />
    //     <Text
    //       color='#000000'
    //       fontSize='18px'
    //       lineHeight='18px'
    //       fontWeight='400'
    //     >
    //       {t('scan-scene.tutorial.lens-selection')}
    //     </Text>
    //   </Flex>
    //   <Flex
    //     gap='20px'
    //     alignItems='center'
    //   >
    //     <Flex
    //       background='#B19477'
    //       color='#ffffff'
    //       fontSize='10px'
    //       borderRadius='6px'
    //       justifyContent='center'
    //       alignItems='center'
    //       p='2px 5px'
    //       boxShadow='0px 0px 10px 0px rgba(0, 0, 0, 0.1)'
    //     >
    //       {t('scan-scene.find-button')}
    //     </Flex>
    //     <Text
    //       color='#000000'
    //       fontSize='18px'
    //       lineHeight='18px'
    //       fontWeight='400'
    //     >
    //       {t('scan-scene.tutorial.lens-button')}
    //     </Text>
    //   </Flex>
    // </Flex>
  );
};

