import { Flex, Image, Text } from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';
import AboutUsImg from '../../../components/Images/AboutUs/AboutUsImg.jpg';
import CodeIcon from '../../../components/Images/AboutUs/CodeIcon.svg';
import LampIcon from '../../../components/Images/AboutUs/LampIcon.svg';
import PenIcon from '../../../components/Images/AboutUs/PenIcon.svg';

export default function AboutUsModule() {
  const { t } = useTranslation();

  return (
    <Flex
      w='1218px'
      m='60px 0 150px'
      gap='30px'
    >
      <Flex
        w="100%"
        flexDirection='column'
      >
        <Text
          fontSize='46px'
          lineHeight='46px'
          fontWeight='700'
          color='#000000'
          mb='24px'
          w='80%'
        >
          {t('about-us.header')}
        </Text>
        <Text
          fontSize='20px'
          lineHeight='24px'
          fontWeight='400'
          color='#9b9b9b'
          mb='42px'
        >
          🇰🇿 {t('about-us.subheader')}
        </Text>
        <Flex
          w='100%'
          h='auto'
          borderRadius='12px'
          justifyContent='center'
          alignItems='center'
          overflow='hidden'
        >
          <Image
            w='100%'
            h='auto'
            src={AboutUsImg}
          />
        </Flex>
      </Flex>
      <Flex
        w="100%"
        flexDirection='column'
        gap='24px'
      >
        <Flex
          w='100%'
          flexDirection='column'
          background='#ffffff'
          borderRadius='12px'
          p='30px'
          gap='10px'
        >
          <Flex
            w='100%'
            alignItems='center'
            gap='14px'
          >
            <Flex
              w='38px'
              h='38px'
              bg='#efeae4'
              borderRadius='50%'
              justifyContent='center'
              alignItems='center'
            >
              <Image
                w='24px'
                h='24px'
                src={CodeIcon}
              />
            </Flex>
            <Text
              fontSize='20px'
              lineHeight='28px'
              fontWeight='500'
            >
              {t('about-us.title1')}
            </Text>
          </Flex>
          <Text
            fontSize='14px'
            lineHeight='20px'
            fontWeight='400'
            color='#9b9b9b'
          >
            {t('about-us.text1')}
          </Text>
        </Flex>
        <Flex
          w='100%'
          flexDirection='column'
          background='#ffffff'
          borderRadius='12px'
          p='30px'
          gap='10px'
        >
          <Flex
            w='100%'
            alignItems='center'
            gap='14px'
          >
            <Flex
              w='38px'
              h='38px'
              bg='#efeae4'
              borderRadius='50%'
              justifyContent='center'
              alignItems='center'
            >
              <Image
                w='24px'
                h='24px'
                src={PenIcon}
              />
            </Flex>
            <Text
              fontSize='20px'
              lineHeight='28px'
              fontWeight='500'
            >
              {t('about-us.title2')}
            </Text>
          </Flex>
          <Text
            fontSize='14px'
            lineHeight='20px'
            fontWeight='400'
            color='#9b9b9b'
          >
            {t('about-us.text2')}
          </Text>
        </Flex>
        <Flex
          w='100%'
          flexDirection='column'
          background='#ffffff'
          borderRadius='12px'
          p='30px'
          gap='10px'
        >
          <Flex
            w='100%'
            alignItems='center'
            gap='14px'
          >
            <Flex
              w='38px'
              h='38px'
              bg='#efeae4'
              borderRadius='50%'
              justifyContent='center'
              alignItems='center'
            >
              <Image
                w='24px'
                h='24px'
                src={LampIcon}
              />
            </Flex>
            <Text
              fontSize='20px'
              lineHeight='28px'
              fontWeight='500'
            >
              {t('about-us.title3')}
            </Text>
          </Flex>
          <Text
            fontSize='14px'
            lineHeight='20px'
            fontWeight='400'
            color='#9b9b9b'
          >
            {t('about-us.text3')}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}