import { Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../services/hooks/authService";

export default function BtnLogout() {
  const { handlerAuthLogOut } = useAuth();
  const { t } = useTranslation();
  
  return (
    <Button
      w='166px'
      h='54px'
      color='#ffffff'
      fontSize='16px'
      fontWeight='700'
      borderRadius='12px'
      p="18px 24px"
      bg='#000000'
      onClick={handlerAuthLogOut}
      _hover={{ backgroundColor: '#262626' }}
      _active={{ backgroundColor: '#333333' }}
      _focus={{ backgroundColor: '#B19477' }}
    >
        {t('profile.logout-modal.button-yes')}
    </Button>
  )
}