import { Button, Fade, Flex, Image, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import FeaturesList from "../../Main/LastModule/FeaturesList";
import BtnGoPremium from "../../../components/Buttons/BtnGoPremium";
import LastModuleImg from "../../../components/Images/LastModuleImg.png";
import { useDispatch, useSelector } from "react-redux";

export default function PremiumSection() {
  const { t } = useTranslation();
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const user = useSelector(store => store.user);
  const dispatch = useDispatch()

  const unsubscribe = () => {
    const response = fetch(`${serverUrl}/auth/unsubscribe`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: user.id }),
      credentials: 'include'
    });
    
    response
      .then(res => {
        if (!res.ok) {
          throw new Error(`Server error, status: ${res.status}`);
        }
        dispatch({ type: 'user/updateRole', payload: 'user' });
      })
      .catch(error => console.log(error))
  }

  return (
    <>
      <Text
        fontSize='28px'
        fontWeight='500'
        lineHeight='36px'
        color='#000000'
      >
        {t('profile.side-section.my-subscription')}
      </Text>
      <Fade
        in
        transition={{ enter: { duration: 0.5 } }}
      >
        {user?.role === 'member'
          ?
          <Flex
            w='100%'
            bg='#ffffff'
            borderRadius='12px'
            p='68px 30px'
            flexDirection='column'
            alignItems='center'
          >
            <Text
              fontSize='28px'
              fontWeight='500'
              lineHeight='36px'
              color='#000000'
              mb='60px'
            >
              Success!
            </Text>
            <Button
              onClick={unsubscribe}
            >
              Unsubscribe
            </Button>
          </Flex>
          :
          <Flex
            w='100%'
            bg='#ffffff'
            borderRadius='12px'
            p='68px 30px'
            flexDirection='column'
            alignItems='center'
          >
            <Text
              fontSize='28px'
              fontWeight='500'
              lineHeight='36px'
              color='#000000'
              mb='60px'
            >
              {t('profile.premium-section.header')}
            </Text>
            <Flex
              w='100%'
            >
              <Flex
                position='relative'
                w='50%'
              >
                <Image
                  top='-35px'
                  left='30px'
                  w='90%'
                  position='absolute'
                  src={LastModuleImg}
                />
              </Flex>
              <Flex
                flexDirection='column'
                w='50%'
                p='0 80px 0 55px'
                gap='50px'
              >
                <FeaturesList />
                <BtnGoPremium />
              </Flex>
            </Flex>
          </Flex>
        }
      </Fade>
    </>
  )
}