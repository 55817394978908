import { Button, useDisclosure } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import ModalComponent from "../ModalComponent";
import AuthModule from "../Auth/AuthModule";

export default function BtnLogin() {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button
        w='88px'
        h='54px'
        color='#000000'
        fontSize='16px'
        fontWeight='500'
        border='1px solid #0000001A'
        borderRadius='12px'
        mr='5px'
        p="18px 24px"
        bg='none'
        onClick={onOpen}
        _focus={{ borderColor: '#B19477' }}
        _hover={{ backgroundColor: '#B1947733' }}
        _active={{ backgroundColor: '#B1947740' }}
      >
        {t('nav.login')}
      </Button>
      <ModalComponent
        isOpen={isOpen}
        onClose={onClose}
        children={<AuthModule authSwitch={true}  onClose={onClose} />}
      />
    </>
  )
}