import { createSlice } from '@reduxjs/toolkit';

const ordersSlice = createSlice({
  name: 'orders',
  initialState: [],
  reducers: {
    setOrder: (state, action) => {
      const { orders, orderItems, deliveryAddresses } = action.payload
      const newState = orders?.map((order) => {
        return {
          id: order.id,
          orderInfo: order,
          items: orderItems.filter(item => item.order_id === order.id),
          deliveryAddress: deliveryAddresses.find(address => address.id === order.delivery_address_id)
        }
      });
      return newState;
    },
    addOrder: (state, action) => {
      const { orders, orderItems, deliveryAddresses } = action.payload
      state.push({
        id: orders.id,
        orderInfo: orders,
        items: orderItems,
        deliveryAddress: deliveryAddresses
      });
      return state;
    },
    clearOrders: (state, action) => {
      return [];
    },
  },
});

export const { setOrder, addOrder, clearOrders } = ordersSlice.actions;

export default ordersSlice.reducer;
