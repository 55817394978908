import { createSlice } from '@reduxjs/toolkit';

const cartSlice = createSlice({
  name: 'cart',
  initialState: [],
  reducers: {
    setCart: (state, action) => {
      return action.payload;
    },
    addCart: (state, action) => {
      return [...state, action.payload];
    },
    clearCart: (state, action) => {
      return [];
    },
  },
});

export const { setCart, clearCart, addCart } = cartSlice.actions;

export default cartSlice.reducer;