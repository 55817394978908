import { Button, Image, Text } from "@chakra-ui/react";
import Star1 from '../Images/Star1.svg';
import { useNavigate } from "react-router-dom";

export default function BtnPremium() {
  const navigate = useNavigate();
  
  return (
    <Button
      borderRadius='12px'
      p="18px 24px"
      h='54px'
      bg='#B194771A'
      onClick={() => navigate('/membership')}
      _hover={{ backgroundColor: '#B1947733' }}
      _active={{ backgroundColor: '#B1947740' }}
      _focus={{ border: '1px solid #B19477' }}
    >
        <Image  
          src={Star1}
          />
          <Text
            ml='8px'
            color='#B19477'
            fontSize='18px'
            fontWeight='500'
          >
            Membership
          </Text>
    </Button>
  )
}