import { Flex, Image, Fade, Text } from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';
import RectWave from '../../../components/Images/Rectangle3.png';
import AnimationSvg from '../../../components/Images/AnimationMain.svg';
import UploadModule from "./UploadModule";
import { useEffect, useRef, useState } from "react";

export default function ScanModule() {
  const { t } = useTranslation();
  const [textSlider, setTextSlider] = useState(0);
  const indexRef = useRef(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      switch (indexRef.current) {
        case 1:
          setTextSlider(1);
          indexRef.current += 1;
          break;
        case 2:
          setTextSlider(2);
          indexRef.current = 0;
          break;
        default:
          setTextSlider(0);
          indexRef.current += 1;
          break;
      }
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Flex
      backgroundImage={`linear-gradient(90deg, #FAE4CE40, #D2965340), url(${RectWave})`}
      w='1218px'
      h='407px'
      borderRadius='40px'
      p='56px 82px'
    >
      <Flex
        flexDirection='column'
      >
        <Flex
          w='574px'
          h='132px'
          overflow='hidden'
        >
          <Fade
            in={textSlider === 0}
            unmountOnExit
            transition={{
              enter: { duration: 1 }
            }}
          >
            <Text
              fontSize='56px'
              fontWeight='500'
              color='#000000'
              w='574px'
              h='132px'
              lineHeight='66px'
              letterSpacing='0px'
            >
              {t('main.scan-module.text')}
            </Text>
          </Fade>
          <Fade
            in={textSlider === 1}
            unmountOnExit
            transition={{
              enter: { duration: 1 }
            }}
          >
            <Text
              fontSize='56px'
              fontWeight='500'
              color='#000000'
              w='574px'
              h='132px'
              lineHeight='66px'
              letterSpacing='0px'
            >
              {t('main.scan-module.text2')}
            </Text>
          </Fade>
          <Fade
            in={textSlider === 2}
            unmountOnExit
            transition={{
              enter: { duration: 1 }
            }}
          >
            <Text
              fontSize='56px'
              fontWeight='500'
              color='#000000'
              w='574px'
              h='132px'
              lineHeight='66px'
              letterSpacing='0px'
            >
              {t('main.scan-module.text3')}
            </Text>
          </Fade>
        </Flex>
        <Text
          fontSize='18px'
          opacity='0.4'
          w='574px'
          h='30px'
          m='16px 0px 37px'
          fontWeight='400'
          lineHeight='30px'
        >
          {t('main.scan-module.subtext')}
        </Text>
        <UploadModule />
      </Flex>
      <Flex
        position='relative'
        w='574px'
      >
        <Image
          position='absolute'
          top='-50px'
          right='-20px'
          w='456px'
          h='400px'
          src={AnimationSvg}
        />
      </Flex>
    </Flex>
  )
}