import { Flex, Image, Text } from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';
import AIIcon from '../../../components/Images/KeyFeaturesIcons/AIIcon.svg';
import BrushIcon from '../../../components/Images/KeyFeaturesIcons/BrushIcon.svg';
import BoxIcon from '../../../components/Images/KeyFeaturesIcons/BoxIcon.svg';
import ShopIcon from '../../../components/Images/KeyFeaturesIcons/ShopIcon.svg';
import SettingIcon from '../../../components/Images/KeyFeaturesIcons/SettingIcon.svg';
import UploadIcon from '../../../components/Images/KeyFeaturesIcons/UploadIcon.svg';

export default function KeyFeaturesModule() {
  const { t } = useTranslation();

  return (
    <Flex
      w='1218px'
      h='600px'
      m='60px 0 90px'
      flexDirection='column'
      gap='45px'
    >
      <Text
        fontSize='46px'
        lineHeight='46px'
        fontWeight='700'
        color='#000000'
        textAlign='center'
      >
        {t('key-features.header')}
      </Text>
      <Flex
        gap='24px'
        flexWrap='wrap'
      >
        <Flex
          w='386px'
          h='226px'
          flexDirection='column'
          background='#ffffff'
          borderRadius='12px'
          p='30px'
          gap='8px'
        >
          <Flex
            w='38px'
            h='38px'
            bg='#efeae4'
            borderRadius='50%'
            justifyContent='center'
            alignItems='center'
          >
            <Image
              w='24px'
              h='24px'
              src={AIIcon}
            />
          </Flex>
          <Text
            fontSize='20px'
            lineHeight='28px'
            fontWeight='500'
          >
            {t('key-features.title1')}
          </Text>
          <Text
            fontSize='14px'
            lineHeight='20px'
            fontWeight='400'
            color='#9b9b9b'
          >
            {t('key-features.text1')}
          </Text>
        </Flex>
        <Flex
          w='386px'
          h='226px'
          flexDirection='column'
          background='#ffffff'
          borderRadius='12px'
          p='30px'
          gap='8px'
        >
          <Flex
            w='38px'
            h='38px'
            bg='#efeae4'
            borderRadius='50%'
            justifyContent='center'
            alignItems='center'
          >
            <Image
              w='24px'
              h='24px'
              src={BrushIcon}
            />
          </Flex>
          <Text
            fontSize='20px'
            lineHeight='28px'
            fontWeight='500'
          >
            {t('key-features.title2')}
          </Text>
          <Text
            fontSize='14px'
            lineHeight='20px'
            fontWeight='400'
            color='#9b9b9b'
          >
            {t('key-features.text2')}
          </Text>
        </Flex>
        <Flex
          w='386px'
          h='226px'
          flexDirection='column'
          background='#ffffff'
          borderRadius='12px'
          p='30px'
          gap='8px'
        >
          <Flex
            w='38px'
            h='38px'
            bg='#efeae4'
            borderRadius='50%'
            justifyContent='center'
            alignItems='center'
          >
            <Image
              w='24px'
              h='24px'
              src={UploadIcon}
            />
          </Flex>
          <Text
            fontSize='20px'
            lineHeight='28px'
            fontWeight='500'
          >
            {t('key-features.title3')}
          </Text>
          <Text
            fontSize='14px'
            lineHeight='20px'
            fontWeight='400'
            color='#9b9b9b'
          >
            {t('key-features.text3')}
          </Text>
        </Flex>
        <Flex
          w='386px'
          h='226px'
          flexDirection='column'
          background='#ffffff'
          borderRadius='12px'
          p='30px'
          gap='8px'
        >
          <Flex
            w='38px'
            h='38px'
            bg='#efeae4'
            borderRadius='50%'
            justifyContent='center'
            alignItems='center'
          >
            <Image
              w='24px'
              h='24px'
              src={ShopIcon}
            />
          </Flex>
          <Text
            fontSize='20px'
            lineHeight='28px'
            fontWeight='500'
          >
            {t('key-features.title4')}
          </Text>
          <Text
            fontSize='14px'
            lineHeight='20px'
            fontWeight='400'
            color='#9b9b9b'
          >
            {t('key-features.text4')}
          </Text>
        </Flex>
        <Flex
          w='386px'
          h='226px'
          flexDirection='column'
          background='#ffffff'
          borderRadius='12px'
          p='30px'
          gap='8px'
        >
          <Flex
            w='38px'
            h='38px'
            bg='#efeae4'
            borderRadius='50%'
            justifyContent='center'
            alignItems='center'
          >
            <Image
              w='24px'
              h='24px'
              src={SettingIcon}
            />
          </Flex>
          <Text
            fontSize='20px'
            lineHeight='28px'
            fontWeight='500'
          >
            {t('key-features.title5')}
          </Text>
          <Text
            fontSize='14px'
            lineHeight='20px'
            fontWeight='400'
            color='#9b9b9b'
          >
            {t('key-features.text5')}
          </Text>
        </Flex>
        <Flex
          w='386px'
          h='226px'
          flexDirection='column'
          background='#ffffff'
          borderRadius='12px'
          p='30px'
          gap='8px'
        >
          <Flex
            w='38px'
            h='38px'
            bg='#efeae4'
            borderRadius='50%'
            justifyContent='center'
            alignItems='center'
          >
            <Image
              w='24px'
              h='24px'
              src={BoxIcon}
            />
          </Flex>
          <Text
            fontSize='20px'
            lineHeight='28px'
            fontWeight='500'
          >
            {t('key-features.title6')}
          </Text>
          <Text
            fontSize='14px'
            lineHeight='20px'
            fontWeight='400'
            color='#9b9b9b'
          >
            {t('key-features.text6')}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}